import { AUTHORITIES, PERMISSIONS } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { Translate, getPaginationState, translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Badge, Card } from 'reactstrap';
import { reset } from './device.reducer';
import { reset as resetDeviceLocation } from './location/location.reducer';
import { createColumnHelper } from '@tanstack/react-table';
import ReactTable from 'app/modules/common/table';
import AddLinkIcon from 'app/shared/icons/link-icon';
import { getEntities } from './device.reducer';
import { useColumnFiltersQueryString } from 'app/shared/util/table-utils';
import { DEVICE_TABLE_QUERY_KEYS } from './utils';
import { hasPermissions } from 'app/shared/auth/private-route';

export const Device = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id', 'desc'), pageLocation.search),
  );

  const deviceList = useAppSelector(state => state.device.entities);
  const loading = useAppSelector(state => state.device.loading);
  const totalItems = useAppSelector(state => state.device.totalItems);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const permissions = useAppSelector(state => state.authentication.account.permissions);
  const canLinkDevice = hasPermissions(permissions, [PERMISSIONS.ALL, PERMISSIONS.SETUP_DEVICE_EDIT]);
  const applicationUser = useAppSelector(state => state.authentication?.internalUser);
  const columnHelper = createColumnHelper();
  const isPlatformUser = authorities.some(r => [AUTHORITIES.ADMIN, AUTHORITIES.USER, AUTHORITIES.SUPERADMIN].includes(r));
  const isDeviceOwner = authorities.some(r => [AUTHORITIES.DEVICE_OWNER].includes(r));

  const { columnFilters, setColumnFilters, queryStringWithPagination } = useColumnFiltersQueryString({
    queryStringAPIFilterKeys: DEVICE_TABLE_QUERY_KEYS,
    pagination: paginationState,
    setPagination: setPaginationState,
  });
  const getAllEntities = () => {
    let filterUrl = 'userId.specified=true&';

    if (authorities.some(r => [AUTHORITIES.DEVICE_OWNER].includes(r))) {
      filterUrl = filterUrl + `userId.equals=${applicationUser?.id}&`;
    } else if (authorities.some(r => [AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.UTILITY_USER].includes(r))) {
      filterUrl = filterUrl + `orgId.equals=${applicationUser?.organization?.id}&`;
    }

    dispatch(
      getEntities({
        filterUrl: filterUrl + queryStringWithPagination,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };
  useEffect(() => {
    dispatch(reset());
    dispatch(resetDeviceLocation());
  }, []);

  useEffect(() => {
    if (!applicationUser) {
      return;
    }
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, applicationUser, columnFilters]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const columns = [
    columnHelper.accessor('name', {
      header: translate('rainspotApp.device.name'),
    }),
    columnHelper.accessor('serialNumber', {
      header: translate('rainspotApp.device.serialNumber'),
    }),
    columnHelper.accessor('installedAddress', {
      header: translate('rainspotApp.device.address'),
      cell(col: any) {
        return <>{col?.row?.original?.deviceLocation?.deviceAddress}</>;
      },
    }),
    // columnHelper.accessor('neighborhood', {
    //   header: translate('rainspotApp.device.neighborhood'),
    //   cell(col: any) {
    //     return <>{col?.row?.original?.neighborhood?.name}</>;
    //   },
    // }),
    columnHelper.accessor('state', {
      cell(col: any) {
        return (
          <Badge color={col?.row?.original?.state === 'ONLINE' ? 'success' : 'danger'}>
            {col?.row?.original?.state === 'ONLINE' ? 'Online' : 'Offline'}
          </Badge>
        );
      },
      header: translate('rainspotApp.device.state'),
      size: 120,
    }),
  ];

  return (
    <Card className="jh-card">
      <h2 id="device-heading" data-cy="DeviceHeading">
        <div className="d-flex justify-content-end">
          {canLinkDevice && (
            <Link to="/device/link" className="btn btn-primary jh-create-entity ml-5" id="linkEntity" data-cy="entityCreateButton">
              <AddLinkIcon />
              &nbsp;
              <Translate contentKey="rainspotApp.device.home.linkDevice">Link Device</Translate>
            </Link>
          )}
        </div>
      </h2>

      <ReactTable
        columns={columns}
        data={deviceList}
        totalItems={totalItems}
        handlePagination={handlePagination}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        isLoading={loading}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        onRowClick={(e, row) => {
          const id = row?.original?.id;
          navigate(`/device/${id}/edit`, { state: { from: { path: '/device' } } });
        }}
      ></ReactTable>
    </Card>
  );
};

export default Device;
