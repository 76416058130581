import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Translate, getPaginationState, translate } from 'react-jhipster';
import { useLocation, useNavigate } from 'react-router-dom';

import { createColumnHelper } from '@tanstack/react-table';
import { AUTHORITIES, PERMISSIONS } from 'app/config/constants';
import { getEntities as getAuthorities } from 'app/entities/authorities/authorities.reducer';
import { reset as resetCities } from 'app/entities/city/city.reducer';
import ReactTable from 'app/modules/common/table';
import { formatDate } from 'app/shared/util/date-utils';
import Avatar from 'react-avatar';
import { Badge, Button, Card } from 'reactstrap';
import { OrganizationContext } from '../organization/organization-update';
import { getEntitiesView, reset } from './application-user.reducer';
import { useColumnFiltersQueryString } from 'app/shared/util/table-utils';
import { USER_TABLE_QUERY_KEYS } from './utils';
import { hasPermissions } from 'app/shared/auth/private-route';

export const ApplicationUserWrapper = () => {
  return (
    <Card className="jh-card">
      <ApplicationUser />
    </Card>
  );
};

export const ApplicationUser = () => {
  const dispatch = useAppDispatch();
  const columnHelper = createColumnHelper();
  const organizationContext = useContext(OrganizationContext);
  const authoritiesOption = useAppSelector(state => state.authorities.entities);
  const applicationUser = useAppSelector(state => state.authentication?.internalUser);
  const {
    id: organizationId,
    setOrganizationUser,
    setMode,
  } = organizationContext ?? { id: null, setOrganizationUser: null, setMode: null };

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id', 'desc'), pageLocation.search),
  );

  const applicationUserList = useAppSelector(state => state.applicationUser.entities);
  const loading = useAppSelector(state => state.applicationUser.loading);
  const totalItems = useAppSelector(state => state.applicationUser.totalItems);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const permissions = useAppSelector(state => state.authentication.account.permissions);
  const canInviteNewUsers = hasPermissions(permissions, [PERMISSIONS.ALL, PERMISSIONS.USER_INVITE_USER_EDIT]);

  const { columnFilters, setColumnFilters, queryStringWithPagination } = useColumnFiltersQueryString({
    queryStringAPIFilterKeys: USER_TABLE_QUERY_KEYS,
    pagination: paginationState,
    setPagination: setPaginationState,
  });

  const getAllEntities = () => {
    let filterUrl = '';
    if (organizationId) {
      filterUrl = `organizationId.equals=${organizationId}&`;
    } else if (authorities.some(r => [AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.UTILITY_USER].includes(r))) {
      filterUrl = `organizationId.equals=${applicationUser?.organization?.id}&`;
    }

    dispatch(
      getEntitiesView({
        filterUrl: filterUrl + queryStringWithPagination,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    if (!applicationUser) {
      return;
    }
    sortEntities();
    dispatch(reset());
    dispatch(resetCities());
  }, [paginationState.activePage, paginationState.order, paginationState.sort, organizationId, applicationUser, columnFilters]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  useEffect(() => {
    dispatch(getAuthorities({}));
  }, []);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('userEmail', {
        cell(col: any) {
          const row = col.row;

          return (
            <div style={{ display: 'flex', gap: '10px', marginLeft: '-15px' }}>
              <Avatar
                size="36"
                round={true}
                name={row?.original?.userFirstName + ' ' + row?.original?.userLastName}
                src={row?.original?.imageUrl}
                color="#bbb"
                className="avatar"
              />
              <div style={{ display: 'flex', alignItems: 'center' }}>{row?.original?.userEmail ?? ''}</div>
            </div>
          );
        },
        size: 250,
        header: translate('rainspotApp.applicationUser.email'),
      }),
      columnHelper.accessor('userFirstName', {
        cell(col: any) {
          return col?.row?.original?.userFirstName ?? '';
        },
        header: translate('rainspotApp.applicationUser.firstName'),
      }),
      columnHelper.accessor('userLastName', {
        cell(col: any) {
          return col?.row?.original?.userLastName ?? '';
        },
        header: translate('rainspotApp.applicationUser.lastName'),
      }),
      columnHelper.accessor('account', {
        cell(col: any) {
          const row = col?.row?.original;
          return row?.account ?? '';
        },
        header: translate('rainspotApp.applicationUser.userName'),
      }),
      columnHelper.accessor('activated', {
        cell(col: any) {
          return col?.row?.original?.activated === 'Activated' ? (
            <Badge color="success">Activated</Badge>
          ) : (
            <Badge color="danger">Deactivated</Badge>
          );
        },
        header: translate('rainspotApp.applicationUser.status'),
      }),
    ];
  }, [authoritiesOption]);

  return (
    <>
      {canInviteNewUsers && (
        <h2 id="application-user-heading" data-testid="applicationUserHeading" data-cy="ApplicationUserHeading">
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => {
                if (organizationId) {
                  setOrganizationUser(null);
                  setMode('new');
                } else {
                  navigate('/application-user/new');
                }
              }}
              className="btn btn-primary jh-create-entity"
              id="jh-create-entity"
              data-cy="entityCreateButton"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="rainspotApp.applicationUser.home.createLabel">Create new Application User</Translate>
            </Button>
          </div>
        </h2>
      )}
      <ReactTable
        columns={columns}
        data={applicationUserList}
        totalItems={totalItems}
        handlePagination={handlePagination}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        isLoading={loading}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        onRowClick={(e, row) => {
          const id = row?.original?.id;
          if (!organizationId) {
            navigate(`/application-user/${id}/edit`);
          } else {
            setOrganizationUser?.(id);
            setMode('edit');
          }
        }}
      ></ReactTable>
    </>
  );
};

export default ApplicationUser;
