import React from 'react';
import { Translate } from 'react-jhipster';
import DeviceIcon from 'app/shared/icons/device-icons';
import HiveIcon from 'app/shared/icons/hive-icon';
import MapsIcon from 'app/shared/icons/maps-icon';
import NeighborhoodIcon from 'app/shared/icons/neighborhood-icon';
import OrganizationIcon from 'app/shared/icons/organization-icon';
import UsersIcon from 'app/shared/icons/user-icon';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Nav } from 'reactstrap';
import { AUTHORITIES, PERMISSIONS } from 'app/config/constants';
import MetricIcon from 'app/shared/icons/metric-icon';
import InventoryIcon from 'app/shared/icons/inventory-icon copy';
import MessageIcon from 'app/shared/icons/messages-icon';
import RoleIcon from 'app/shared/icons/role-icon';

export const EntitiesMenu = ({ authorities, permissions }) => {
  const roleBasedMenu = menu.filter(m => m.permissions.some(r => permissions.includes(r))); // menu.filter(m => m.authorities.some(r => authorities.includes(r)));

  return (
    <>
      <Nav vertical>
        {roleBasedMenu.map((menuItem, index) => (
          <MenuItem id={'item-' + menuItem.id} key={index} icon={null} to={menuItem.to}>
            {menuItem.icon}
            <Translate contentKey={menuItem.title} />
          </MenuItem>
        ))}

        {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
      </Nav>
    </>
  );
};

export const InternalEntities = ({ authorities, permissions }) => {
  const roleBasedMenu = internalEntities.filter(m => m.permissions.some(r => permissions.includes(r)));
  return (
    <>
      {roleBasedMenu?.length > 0 && <div className="menu-section">INTERNAL</div>}
      <Nav vertical>
        {roleBasedMenu.map((menuItem, index) => (
          <MenuItem id={'item-' + menuItem.id} key={index} icon={null} to={menuItem.to}>
            {menuItem.icon}
            <Translate contentKey={menuItem.title} />
          </MenuItem>
        ))}

        {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
      </Nav>
    </>
  );
};

export const AdministrationMenu = ({ authorities, permissions }) => {
  const roleBasedMenu = administrationMenu.filter(m => m.permissions.some(r => permissions.includes(r)));
  return (
    <>
      {roleBasedMenu?.length > 0 && <div className="menu-section">ADMINISTRATIONS</div>}
      <Nav vertical>
        {roleBasedMenu.map((menuItem, index) => (
          <MenuItem id={'item-' + menuItem.id} key={index} icon={null} to={menuItem.to}>
            {menuItem.icon}
            <Translate contentKey={menuItem.title} />
          </MenuItem>
        ))}

        {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
      </Nav>
    </>
  );
};

const menu = [
  {
    id: 'device',
    to: '/device',
    title: 'global.menu.entities.device',
    icon: <DeviceIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.UTILITY_USER, AUTHORITIES.DEVICE_OWNER],
    permissions: [PERMISSIONS.DEVICE_EDIT, PERMISSIONS.DEVICE_READ, PERMISSIONS.ALL],
  },
  {
    id: 'hive',
    to: '/hive',
    title: 'global.menu.entities.hive',
    icon: <HiveIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.UTILITY_USER],
    permissions: [PERMISSIONS.HIVE_EDIT, PERMISSIONS.HIVE_READ, PERMISSIONS.ALL],
  },
  {
    id: 'maps',
    to: '/maps',
    title: 'global.menu.entities.maps',
    icon: <MapsIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.UTILITY_ADMIN],
    permissions: [PERMISSIONS.MAPS_EDIT, PERMISSIONS.MAPS_READ, PERMISSIONS.ALL],
  },
  {
    id: 'messages',
    to: '/messages',
    title: 'global.menu.entities.messages',
    icon: <MessageIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.UTILITY_ADMIN],
    permissions: [PERMISSIONS.MESSAGES_EDIT, PERMISSIONS.MESSAGES_READ, PERMISSIONS.ALL],
  },
];

const internalEntities = [
  {
    to: '/comment',
    id: 'comment',
    title: 'global.menu.entities.comment',
    icon: <>*</>,
    authorities: [AUTHORITIES.SUPERADMIN],
    permissions: [PERMISSIONS.ALL],
  },
  {
    id: 'city',
    to: '/city',
    title: 'global.menu.entities.city',
    icon: <>*</>,
    authorities: [AUTHORITIES.SUPERADMIN],
    permissions: [PERMISSIONS.ALL],
  },
  {
    id: 'device-payload',
    to: '/device-payload',
    title: 'global.menu.entities.devicePayload',
    icon: <>*</>,
    authorities: [AUTHORITIES.SUPERADMIN],
    permissions: [PERMISSIONS.ALL],
  },
  {
    id: 'computed-metric',
    to: '/computed-metric',
    title: 'global.menu.entities.computedMetric',
    icon: <>*</>,
    authorities: [AUTHORITIES.SUPERADMIN],
    permissions: [PERMISSIONS.ALL],
  },
  {
    id: 'report',
    to: '/cypressReport',
    title: 'global.menu.entities.cypressReport',
    icon: <>*</>,
    authorities: [AUTHORITIES.SUPERADMIN],
    permissions: [PERMISSIONS.ALL],
  },
];

const administrationMenu = [
  {
    id: 'inventory',
    to: '/inventory',
    title: 'global.menu.entities.inventory',
    icon: <InventoryIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.SUPERADMIN],
    permissions: [PERMISSIONS.INVENTORY_EDIT, PERMISSIONS.INVENTORY_READ, PERMISSIONS.ALL],
  },
  {
    id: 'organization',
    to: '/organization',
    title: 'global.menu.entities.organization',
    icon: <OrganizationIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.SUPERADMIN],
    permissions: [PERMISSIONS.ORGANIZATION_EDIT, PERMISSIONS.ORGANIZATION_READ, PERMISSIONS.ALL],
  },
  {
    id: 'user',
    to: '/application-user',
    title: 'global.menu.entities.applicationUser',
    icon: <UsersIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.SUPERADMIN],
    permissions: [PERMISSIONS.USER_EDIT, PERMISSIONS.USER_READ, PERMISSIONS.ALL],
  },
  {
    id: 'metric',
    to: '/device-reported-metric',
    title: 'global.menu.entities.metric',
    icon: <MetricIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN],
    permissions: [PERMISSIONS.METRIC_EDIT, PERMISSIONS.METRIC_READ, PERMISSIONS.ALL],
  },
  {
    id: 'access-control',
    to: '/access-control',
    title: 'global.menu.entities.accessControl',
    icon: <RoleIcon />,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.SUPERADMIN],
    permissions: [PERMISSIONS.ALL],
  },
];
