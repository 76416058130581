import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'app/config/store';
import { getEntities as getAuthorities } from 'app/entities/authorities/authorities.reducer';
import React, { useEffect } from 'react';
import { Translate } from 'react-jhipster';
import { Button, Card, Col, Row } from 'reactstrap';
import { reset } from '../permissions/permissions.reducer';
import { PermissionsGrid } from './permissions-grid';
import RolesGrid from './roles-grid';

export const Roles = () => {
  const dispatch = useAppDispatch();
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [isNew, setIsNew] = React.useState(false);

  useEffect(() => {
    dispatch(reset());
    dispatch(getAuthorities({}));
  }, []);

  return (
    <Card className="jh-card">
      {!isNew ? (
        <>
          <Row>
            <Col md="8">
              <h2 id="roles-heading" data-cy="RolesHeading">
                <div className="d-flex justify-content-between">
                  <div>
                    <Translate contentKey="rainspotApp.roles.accessControl">Access Control</Translate>
                  </div>
                  <Button
                    onClick={() => {
                      setSelectedRowId(null);
                      setIsNew(true);
                    }}
                    className="btn btn-primary jh-create-entity ml-5"
                    id="linkEntity"
                    data-cy="entityCreateButton"
                  >
                    <FontAwesomeIcon icon="plus" />
                    &nbsp;
                    <Translate contentKey="rainspotApp.roles.newRole">New Role</Translate>
                  </Button>
                </div>
              </h2>
            </Col>
          </Row>

          <RolesGrid selectedRowId={selectedRowId} setSelectedRowId={setSelectedRowId} />
          {!!selectedRowId && (
            <>
              <div className="mt-10">&nbsp;</div>
              <h2 id="permissions-heading" data-cy="PermissionsHeading">
                <div className="d-flex justify-content-between mt-10">
                  <div>
                    <Translate contentKey="rainspotApp.roles.accessPermissions">Access Permissions</Translate>
                  </div>
                </div>
              </h2>
              <PermissionsGrid selectedRowId={selectedRowId} setSelectedRowId={setSelectedRowId} setIsNew={setIsNew} />
            </>
          )}
        </>
      ) : (
        <>
          <PermissionsGrid selectedRowId={null} setSelectedRowId={setSelectedRowId} setIsNew={setIsNew} />
        </>
      )}
    </Card>
  );
};
