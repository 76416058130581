import { AUTHORITIES, PERMISSIONS } from 'app/config/constants';
import { sprinklerOptions } from './configuration/device-tab-configuration';

export const enum GRAPHTYPE {
  BANDWIDTH = 'BANDWIDTH',
  LINK_QUALITY = 'LINK_QUALITY',
  LATENCY = 'LATENCY',
  RSSI = 'RSSI',
  MODES = 'MODES',
  VOLTAGE = 'VOLTAGE',
}

export const defaultSubRows = [
  { id: '-', serialNumber: '-', installedAddress: '-', rxTraffic: '-', txTraffic: '-', state: '-', nodesCount: '-' },
];

export const mockData = [
  {
    id: 7651,
    hopDepth: null,
    ipv4: 'jj',
    ipv6: 'jjjh',
    macAddress: 'jjjj8',
    uuid: '999ikk',
    serialNumber: '123123123',
    ssid: 'i',
    ssidPassword: 'm',
    timezone: 'm',
    state: 'ON',
    installedAddress: 'Devtek Inc',
    installedAddressZipcode: 'm',
    radioType: 'm',
    name: 'Devtek Device',
    dateCreated: '2024-02-29T19:00:00Z',
    dateUpdated: '2024-02-29T19:00:00Z',
    createdBy: 'j',
    modifiedBy: 'j',
    managed: false,
    testDevice: false,
    master: false,
    lat: 'j',
    longitude: 'j',
    nodeMesh: 'j',
    deviceLocation: {
      deviceAddress: 'Devtek Inc',
    },
    user: {
      id: 4006,
      additionalField: null,
      address: null,
      city: null,
      state: null,
      zipcode: null,
      phone: null,
      createdDate: null,
      internalUser: null,
      organization: null,
    },
    org: {
      id: 1152,
      name: null,
      dateCreated: null,
      dateUpdated: null,
      createdBy: null,
      modifiedBy: null,
      status: null,
      streetAddress: null,
      city: null,
      state: null,
      zipCode: null,
      contactInfo: null,
    },
    city: {
      id: 822,
      name: null,
      region: null,
      state: null,
      county: null,
      organization: null,
    },
    neighborhood: {
      id: 2151,
      name: "Devtek's Neighborhood",
      countId: null,
      stateId: null,
      regionId: null,
      city: null,
      createdBy: null,
      createdDate: null,
    },
    mesh: {
      id: 1351,
      name: null,
      subnet: null,
      numberOfNodes: null,
      uptime: null,
      jitter: null,
      latency: null,
      bandwidth: null,
      throughput: null,
      packetloss: null,
      numberOfPacketsReceived: null,
      numberOfPacketsTransmitted: null,
      packetRetransmittion: null,
      masterNodeSerialNumber: null,
      masterNodeMacAddress: null,
      masterNodeIpv4: null,
      masterNodeIpv6: null,
      neighborhood: null,
    },
    manager: null,
  },
];

export const mockInternalUserAsAdmin = [
  {
    id: 2052,
    additionalField: null,
    address: '132 DURHAM RD',
    city: '815',
    state: 'Vermont',
    zipcode: '1212',
    phone: '(233)-283-2787',
    createdDate: '2024-02-27T07:23:57.472Z',
    internalUser: {
      id: 1,
      login: 'admin',
      firstName: 'Administrator',
      lastName: 'Administrator',
      email: 'admin@localhost.com',
      imageUrl: null,
      activated: true,
      authority: [AUTHORITIES.ADMIN],
    },
    organization: {
      id: 2701,
      name: 'Devtek',
      dateCreated: null,
      dateUpdated: null,
      createdBy: null,
      modifiedBy: null,
      status: null,
      streetAddress: null,
      city: null,
      state: null,
      zipCode: null,
      contactInfo: null,
    },
  },
];
export const mockInternalUserAsDeviceOwner = [
  {
    id: 2052,
    additionalField: null,
    address: '132 DURHAM RD',
    city: '815',
    state: 'Vermont',
    zipcode: '1212',
    phone: '(233)-283-2787',
    createdDate: '2024-02-27T07:23:57.472Z',
    internalUser: {
      id: 1,
      login: 'admin',
      firstName: 'Administrator',
      lastName: 'Administrator',
      email: 'admin@localhost.com',
      imageUrl: null,
      activated: true,
      authority: [AUTHORITIES.DEVICE_OWNER],
    },
  },
];

export const mockInternalUserOrgUser = [
  {
    id: 2052,
    additionalField: null,
    address: '132 DURHAM RD',
    city: '815',
    state: 'Vermont',
    zipcode: '1212',
    phone: '(233)-283-2787',
    createdDate: '2024-02-27T07:23:57.472Z',
    internalUser: {
      id: 1,
      login: 'utilityAdmin',
      firstName: 'Utility',
      lastName: 'Admin',
      email: 'utilityA_admin@localhost.com',
      imageUrl: null,
      activated: true,
      authority: [AUTHORITIES.UTILITY_ADMIN],
    },
    organization: {
      id: 2701,
      name: 'Devtek',
      dateCreated: null,
      dateUpdated: null,
      createdBy: null,
      modifiedBy: null,
      status: null,
      streetAddress: null,
      city: null,
      state: null,
      zipCode: null,
      contactInfo: null,
    },
  },
];

export const mockAccount = {
  id: 1,
  login: 'admin',
  firstName: 'Administrator',
  lastName: 'Administrator',
  email: 'admin@localhost.com',
  imageUrl: null,
  activated: true,
  langKey: 'en',
  createdBy: 'system',
  createdDate: null,
  lastModifiedBy: 'admin',
  lastModifiedDate: '2024-03-04T19:46:42.239855Z',
  authorities: ['ROLE_ADMIN'],
  permissions: [PERMISSIONS.DEVICE_EDIT, PERMISSIONS.SETUP_DEVICE_EDIT]
};

export const mockAccountDeviceOwner = {
  id: 1,
  login: 'deviceOwner',
  firstName: 'Device',
  lastName: 'Owner',
  email: 'device@localhost.com',
  imageUrl: null,
  activated: true,
  langKey: 'en',
  createdBy: 'system',
  createdDate: null,
  lastModifiedBy: 'admin',
  lastModifiedDate: '2024-03-04T19:46:42.239855Z',
  authorities: [AUTHORITIES.DEVICE_OWNER],
};

export const mockDataDevicePayload = [
  {
    id: 7547,
    value: [{ "Bandwidth": '102' }],
    direction: 'CLOUDTODEVICE',
    timeOfIngestion: '2024-02-29T19:49:47.862212Z',
    attribute: 'ipv_4',
  },
  {
    id: 7548,
    direction: 'DEVICETOCLOUD',
    value: [{ "upload": '10' }],
    timeOfIngestion: '2024-02-29T19:49:47.862215Z',
    attribute: 'state',
  },
  {
    id: 7549,
    direction: 'CLOUDTODEVICE',
    value: [{ "download": '20' }],
    timeOfIngestion: '2024-02-29T19:49:41.838134Z',
    attribute: 'RSSI',
  },
];

export const mapHealthEntityToFormValues = ({ entity, health }) => {
  return {
    deviceName: entity?.name,
    serialNumber: entity?.serialNumber,
    upload: Array.isArray(health) ? health?.find(h => h.key === 'UPLOAD_SPEED')?.value : '',
    download: Array.isArray(health) ? health?.find(h => h.key === 'DOWNLOAD_SPEED')?.value : '',
    signalStrength: Array.isArray(health) ? health?.find(h => h.key === 'SIGNAL_STRENGTH')?.value : '',
  };
};

export const DEVICE_TABLE_QUERY_KEYS = {
  name: 'name.contains',
  serialNumber: 'serialNumber.contains',
  installedAddress: 'installedAddress.contains',
  neighborhood: 'neighborhood.contains',
  state: 'state.contains',
};

export const mapDeviceEntityToFormValues = ({ deviceEntity }) => {
  return {
    ...deviceEntity,
    master: { label: deviceEntity?.master ? 'Yes' : 'No', value: deviceEntity?.master ? true : false },
    broadcastSSID: { label: deviceEntity?.broadcastSSID ? 'Yes' : 'No', value: deviceEntity?.broadcastSSID ? true : false },
    sprinkler: sprinklerOptions?.find(option => option.value === deviceEntity?.sprinklerStatus),
  };
};

export const mapToMeshPayload = ({ values, deviceEntity }) => {
  const meshPayload = {
    action: {
      master: values?.master?.value,
      sprinkler: values?.sprinkler?.value,
      serial_no: deviceEntity?.serialNumber,
    },
    serialNo: deviceEntity?.serialNumber,
    mesh: {
      id: deviceEntity.mesh?.id,
    },
  };
  return meshPayload;
};
// mock data
export const mockDeviceEntity = {
  id: 12,
  hopDepth: 0,
  ipv4: '103',
  ipv6: '',
  macAddress: '100',
  uuid: '',
  serialNumber: '123456',
  ssid: '',
  ssidPassword: '',
  timezone: '',
  state: 'ON',
  installedAddress: null,
  installedAddressZipcode: null,
  radioType: '',
  name: 'First Device',
  dateCreated: '2024-03-05T19:00:00Z',
  dateUpdated: '2024-03-05T19:00:00Z',
  createdBy: '',
  modifiedBy: '',
  managed: false,
  testDevice: false,
  master: true,
  lat: '',
  longitude: '104',
  sprinklerStatus: 'OFF',
  nodeMesh: '',
  user: {
    id: 12,
    additionalField: null,
    address: null,
    city: null,
    state: null,
    zipcode: null,
    phone: null,
    createdDate: null,
    internalUser: null,
    organization: null,
  },
  org: {
    id: 23,
    name: null,
    dateCreated: null,
    dateUpdated: null,
    createdBy: null,
    modifiedBy: null,
    status: null,
    streetAddress: null,
    city: null,
    state: null,
    zipCode: null,
    contactInfo: null,
  },
  city: null,
  neighborhood: null,
  mesh: null,
  manager: {
    id: 28,
    hopDepth: null,
    ipv4: null,
    ipv6: null,
    macAddress: null,
    uuid: null,
    serialNumber: null,
    ssid: null,
    ssidPassword: null,
    timezone: null,
    state: null,
    installedAddress: null,
    installedAddressZipcode: null,
    radioType: null,
    name: null,
    dateCreated: null,
    dateUpdated: null,
    createdBy: null,
    modifiedBy: null,
    managed: null,
    testDevice: null,
    master: null,
    lat: null,
    longitude: null,
    nodeMesh: null,
    user: null,
    org: null,
    city: null,
    neighborhood: null,
    mesh: null,
    manager: null,
  },
};

export const mockDeviceShadow = {
  state: { desired: { sensor: 'null' }, delta: { sensor: 'null' }, reported: { sprinkler: 'OFF', master: true } },
  metadata: { desired: { sensor: { timestamp: 1711991273 } } },
  version: 2,
  timestamp: 1711991290,
};
