export const ROLE_FILTER_QUERY = {
  name: 'name.contains',
};

export type PermissionsType = {
  id: string | number;
  value: string;
  label: string;
  parent?: string | number;
};

export const mapPermissionsToHierarchy = permissionsArray => {
  const result = [];
  const map = new Map();

  // First pass: create map of all items
  permissionsArray.forEach(item => {
    if (!item.parent) {
      const newItem = { ...item, subRows: [] };
      result.push(newItem);
      map.set(item.id, newItem);
    }
  });

  // Second pass: add children to their parents
  permissionsArray.forEach(item => {
    if (item.parent) {
      const parentItem = map.get(item.parent);
      if (parentItem) {
        parentItem.subRows.push(item);
      }
    }
  });

  return result;
};

export const mapformValuesToPermissions = (permissionsObj, allPermissions) => {
  const result = [{ permissionId: null, action: 'hide', authorityName: permissionsObj?.roleName }];
  const getAction = (hide, read, edit) => {
    if (hide === 'on') return 'hide';
    if (edit === 'on') return 'edit';
    if (read === 'on') return 'read';
    return 'none';
  };

  allPermissions.forEach(module => {
    let action = 'none';
    if (module.parent) {
      const parentValue = module?.key?.split('.')[0];
      const moduleValue = module?.key?.split('.')[1];
      action = getAction(
        permissionsObj?.[parentValue]?.[`${moduleValue}_HIDE`],
        permissionsObj?.[parentValue]?.[`${moduleValue}_READ`],
        permissionsObj?.[parentValue]?.[`${moduleValue}_EDIT`],
      );
    } else {
      action = getAction(
        permissionsObj?.[`${module.key}_HIDE`],
        permissionsObj?.[`${module.key}_READ`],
        permissionsObj?.[`${module.key}_EDIT`],
      );
    }
    if (action !== 'hide' && action !== 'none') {
      result.push({ permissionId: module.id, action, authorityName: permissionsObj?.roleName });
    }
  });
  console.log('result', result);
  return result;
};

export const mapPermissionsToFormValues = (permissions, allPermissions, roleName) => {
  const formValues = { roleName: '' };
  formValues.roleName = permissions[0]?.authorityName ?? roleName;
  // First set all permissions to HIDE by default
  allPermissions.forEach(module => {
    if (module.parent) {
      const parentValue = module.key.split('.')[0];
      const moduleValue = module.key.split('.')[1];
      if (!formValues[parentValue]) {
        formValues[parentValue] = {};
      }
      formValues[parentValue][`${moduleValue}_HIDE`] = 'on';
      formValues[parentValue][`${moduleValue}_READ`] = 'off';
      formValues[parentValue][`${moduleValue}_EDIT`] = 'off';
    } else {
      formValues[`${module.key}_HIDE`] = 'on';
      formValues[`${module.key}_READ`] = 'off';
      formValues[`${module.key}_EDIT`] = 'off';
    }
  });

  // Then override with actual permissions
  permissions.forEach(permission => {
    const hasParent = permission.permissionName.split('.')?.length === 2;
    if (hasParent) {
      const parentValue = permission.permissionName.split('.')[0];
      const moduleValue = permission.permissionName.split('.')[1];
      if (!formValues[parentValue]) {
        formValues[parentValue] = {};
      }
      formValues[parentValue][`${moduleValue}_HIDE`] = 'off';
      formValues[parentValue][`${moduleValue}_READ`] = permission.action === 'read' ? 'on' : 'off';
      formValues[parentValue][`${moduleValue}_EDIT`] = permission.action === 'edit' ? 'on' : 'off';
    } else {
      formValues[`${permission.permissionName}_HIDE`] = 'off';
      formValues[`${permission.permissionName}_READ`] = permission.action === 'read' ? 'on' : 'off';
      formValues[`${permission.permissionName}_EDIT`] = permission.action === 'edit' ? 'on' : 'off';
    }
  });
  return formValues;
};
