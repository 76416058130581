import location from 'app/entities/location/location.reducer';
import comment from 'app/entities/comment/comment.reducer';
import applicationUser from 'app/entities/application-user/application-user.reducer';
import city from 'app/entities/city/city.reducer';
import neighborhood from 'app/entities/neighborhood/neighborhood.reducer';
import serialNumberRange from 'app/entities/serial-number-range/serial-number-range.reducer';
import organization from 'app/entities/organization/organization.reducer';
import device from 'app/entities/device/device.reducer';
import devicePayload from 'app/entities/device-payload/device-payload.reducer';
import metric from 'app/entities/metric/metric.reducer';
import deviceReportedMetric from 'app/entities/device-reported-metric/device-reported-metric.reducer';
import computedMetric from 'app/entities/computed-metric/computed-metric.reducer';
import mesh from 'app/entities/mesh/mesh.reducer';
import states from 'app/entities/city/state.reducer';
import authorities from 'app/entities/authorities/authorities.reducer';
import permissions from 'app/entities/permissions/permissions.reducer';
import county from 'app/entities/city/county.reducer';
import deviceLocation from 'app/entities/device/location/location.reducer';
import inventory from 'app/entities/inventory/inventory.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  location,
  comment,
  applicationUser,
  city,
  neighborhood,
  serialNumberRange,
  organization,
  device,
  devicePayload,
  metric,
  deviceReportedMetric,
  computedMetric,
  mesh,
  states,
  authorities,
  county,
  deviceLocation,
  inventory,
  permissions
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
