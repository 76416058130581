import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import { AUTHORITIES, PERMISSIONS } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { reset as resetCities } from 'app/entities/city/city.reducer';
import ReactTable from 'app/modules/common/table';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { Translate, getPaginationState } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Card } from 'reactstrap';
import { getEntities, reset } from './organization.reducer';
import { useColumnFiltersQueryString } from 'app/shared/util/table-utils';
import { ORG_TABLE_FILTER_QUERY } from './utils';
import { hasPermissions } from 'app/shared/auth/private-route';

export const Organization = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id', 'desc'), pageLocation.search),
  );
  const { columnFilters, setColumnFilters, queryStringWithPagination } = useColumnFiltersQueryString({
    queryStringAPIFilterKeys: ORG_TABLE_FILTER_QUERY,
    pagination: paginationState,
    setPagination: setPaginationState,
  });

  const organizationList = useAppSelector(state => state.organization.entities);
  const loading = useAppSelector(state => state.organization.loading);
  const totalItems = useAppSelector(state => state.organization.totalItems);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const permissions = useAppSelector(state => state.authentication.account.permissions);
  const applicationUser = useAppSelector(state => state.authentication?.internalUser);
  const canAddOrganization = hasPermissions(permissions, [
    PERMISSIONS.ALL,
    PERMISSIONS.ORGANIZATION_ADD_ORG_EDIT,
  ]);

  const getAllEntities = () => {
    let filterUrl = '';
    if (authorities.some(r => [AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.UTILITY_USER].includes(r))) {
      filterUrl = `id.equals=${applicationUser?.organization?.id}&`;
    }
    dispatch(
      getEntities({
        filterUrl: filterUrl + queryStringWithPagination,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    if (!applicationUser) {
      return;
    }
    sortEntities();
    dispatch(resetCities());
    dispatch(reset());
  }, [paginationState.activePage, paginationState.order, paginationState.sort, applicationUser, columnFilters]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
    }),
    columnHelper.accessor('contact', {
      header: 'Contact',
    }),
    columnHelper.accessor('address', {
      header: 'Address',
    }),
    columnHelper.accessor('contactPhone', {
      header: 'Contact Phone',
    }),
    columnHelper.accessor('contactEmail', {
      header: 'Contact Email',
    }),
  ];

  return (
    <Card className="jh-card">
      <h2 id="organization-heading" data-cy="OrganizationHeading" data-testid="OrganizationHeading">
        {canAddOrganization && (
          <div className="d-flex justify-content-end">
            <Link
              to="/organization/new"
              className={`btn btn-primary jh-create-entity`}
              id="jh-create-entity"
              data-testid="entityCreateButton"
              data-cy="entityCreateButton"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="rainspotApp.organization.home.createLabel">Create new Organization</Translate>
            </Link>
          </div>
        )}
      </h2>

      <ReactTable
        columns={columns}
        data={organizationList}
        totalItems={totalItems}
        handlePagination={handlePagination}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        isLoading={loading}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        onRowClick={(e, row) => {
          const id = row?.original?.id;
          navigate(`/organization/${id}/edit`);
        }}
      ></ReactTable>
    </Card>
  );
};

export default Organization;
