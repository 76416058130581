import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Storage } from 'react-jhipster';

import { AUTHORITIES } from 'app/config/constants';
import { AppThunk } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import { IApplicationUser } from '../model/application-user.model';
import { serializeAxiosError } from './reducer.utils';

const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

export const initialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
  error: {} as any,
  internalUser: null,
  permissions: [],
};

export type AuthenticationState = Readonly<typeof initialState>;
const apiUrl = 'api/application-users';
// Actions

export const getSession = (): AppThunk => async (dispatch, getState) => {
  await dispatch(getAccount());

  const { account } = getState().authentication;
  if (account && account.langKey) {
    const langKey = Storage.session.get('locale', account.langKey);
    await dispatch(setLocale(langKey));
    await dispatch(getInternalUserEntity(account.id));
  }
};

export const getInternalUserEntity = createAsyncThunk(
  'applicationUser/fetch_entity_internal',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}?internalUserId.equals=${id}`;
    return axios.get<IApplicationUser[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getAccount = createAsyncThunk('authentication/get_account', async () => axios.get<any>('api/account'), {
  serializeError: serializeAxiosError,
});

interface IAuthParams {
  username: string;
  password: string;
  rememberMe?: boolean;
}

export const authenticate = createAsyncThunk(
  'authentication/login',
  async (auth: IAuthParams) => axios.post<any>('api/authenticate', auth),
  {
    serializeError: serializeAxiosError,
  },
);

export const login: (username: string, password: string, rememberMe?: boolean) => AppThunk =
  (username, password, rememberMe = false) =>
  async dispatch => {
    const result = await dispatch(authenticate({ username, password, rememberMe }));
    const response = result.payload as AxiosResponse;
    const bearerToken = response?.headers?.authorization;
    if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
      const jwt = bearerToken.slice(7, bearerToken.length);
      if (rememberMe) {
        Storage.local.set(AUTH_TOKEN_KEY, jwt);
      } else {
        Storage.session.set(AUTH_TOKEN_KEY, jwt);
      }
    }
    dispatch(getSession());
  };

export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }
};

export const logout: () => AppThunk = () => dispatch => {
  clearAuthToken();
  dispatch(logoutSession());
};

export const clearAuthentication = messageKey => dispatch => {
  clearAuthToken();
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    logoutSession() {
      return {
        ...initialState,
        showModalLogin: true,
      };
    },
    resetAuth() {
      return {
        ...initialState,
      };
    },
    authError(state, action) {
      return {
        ...state,
        showModalLogin: true,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authenticate.rejected, (state, action) => {
        const error = action?.error as AxiosError;
        const errorData = error?.response?.data;
        return {
          ...initialState,
          errorMessage: action.error.message,
          showModalLogin: true,
          loginError: true,
          error: errorData,
        };
      })
      .addCase(authenticate.fulfilled, state => ({
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true,
      }))
      .addCase(getInternalUserEntity.fulfilled, (state, action) => ({
        ...state,
        internalUser: action.payload.data[0],
      }))
      .addCase(getAccount.rejected, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.error.message,
      }))
      .addCase(getAccount.fulfilled, (state, action) => {
        const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
        const account = action.payload?.data;
        const isAdmin =
          account.authorities && (account.authorities.includes(AUTHORITIES.ADMIN) || account.authorities.includes(AUTHORITIES.SUPERADMIN));
        const permissions = [];
        if (!isAdmin) {
          account?.permissions?.forEach(permission => {
            permissions.push(`${permission.permissionName}.${permission?.action?.toUpperCase()}`);
          });
        } else {
          permissions.push('ALL');
        }
        return {
          ...state,
          isAuthenticated,
          loading: false,
          sessionHasBeenFetched: true,
          account: {...action.payload.data, permissions},
        };
      })
      .addCase(authenticate.pending, state => {
        state.loading = true;
      })
      .addCase(getAccount.pending, state => {
        state.loading = true;
      });
  },
});

export const { logoutSession, authError, clearAuth, resetAuth } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
