import React from 'react';

function UnExpandIcon() {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="13.7666"
        y="1.23328"
        width="13.5334"
        height="13.5"
        rx="1.75"
        transform="rotate(90 13.7666 1.23328)"
        stroke="#718096"
        stroke-width="0.5"
      />
      <path
        d="M3.68754 7.24395L7.01662 10.581L10.3457 7.24395L9.52087 6.41714L7.01662 8.92738L4.51237 6.41714L3.68754 7.24395Z"
        fill="#A0AEC0"
      />
    </svg>
  );
}

export default UnExpandIcon;
