import React, { useEffect, useMemo } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, FormFeedback, Input, InputGroup, Label, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateTimeFromServer, displayDefaultDateTime } from 'app/shared/util/date-utils';

import { getEntities as getOrganizations } from 'app/entities/organization/organization.reducer';
import { DEFAULT_ORGANIZATION } from '../application-user/utils';
import { createEntity, getEntity, reset, updateEntity } from './inventory.reducer';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import ReactSelect from 'react-select';
import { reactStrapStyles } from 'app/shared/util/form-utils';
import { find } from 'lodash';
import { AUTHORITIES, PERMISSIONS } from 'app/config/constants';
import { hasPermissions } from 'app/shared/auth/private-route';

export const InventoryUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const organizations = useAppSelector(state => state.organization.menuOptions);
  const organizationEntities = useAppSelector(state => state.organization.entities);
  const deviceEntity = useAppSelector(state => state.inventory.entity);
  const loading = useAppSelector(state => state.inventory.loading);
  const updating = useAppSelector(state => state.inventory.updating);
  const updateSuccess = useAppSelector(state => state.inventory.updateSuccess);
  // const authorities = useAppSelector(state => state.authentication.account.authorities);
  const permissions = useAppSelector(state => state.authentication.account.permissions);
  const isReadOnly = hasPermissions(permissions, [PERMISSIONS.INVENTORY_READ]);
  //const isOrganizationUser = authorities.some(r => [AUTHORITIES.UTILITY_ADMIN, AUTHORITIES.UTILITY_USER].includes(r));

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
    reset: resetForm,
  } = useForm();

  const handleClose = () => {
    navigate('/inventory' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getOrganizations({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    const entity = {
      ...deviceEntity,
      ...values,
      organization: organizationEntities?.find(it => it?.id?.toString() === values?.org?.value?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };
  const defaultValues = useMemo(
    () =>
      isNew
        ? {
            org: organizations?.find(it => it?.label?.toString()?.toUpperCase() === DEFAULT_ORGANIZATION),
          }
        : {
            ...deviceEntity,
            org: organizations?.find(it => it?.value?.toString() === deviceEntity?.organization?.id?.toString()),
          },
    [deviceEntity, isNew, organizations],
  );
  useEffect(() => {
    resetForm({
      ...defaultValues,
    });
  }, [resetForm, defaultValues]);

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="12">
          <h2 id="rainspotApp.device.home.createOrEditLabel" data-cy="DeviceCreateUpdateHeading">
            <Translate contentKey={isNew ? `rainspotApp.device.home.createLabel` : `rainspotApp.device.home.editLabel`}>
              Create or edit a Device
            </Translate>
          </h2>
        </Col>
      </Row>
      <div className="mt-10">&nbsp;</div>
      <Row className="justify-content-start">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form onSubmit={vals => void handleSubmit(saveEntity)(vals)}>
              <div className="form-body">
                <ValidatedField
                  label={translate('rainspotApp.device.serialNumber') + '*'}
                  id="device-serialNumber"
                  name="serialNumber"
                  data-cy="serialNumber"
                  type="text"
                  disabled={isReadOnly}
                  register={register}
                  error={errors?.serialNumber as FieldError}
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
                <div className="d-flex">
                  <div style={{ display: 'flex' }} className="flex-column selectable">
                    <Label for="macAddress">{'Mac Address'}*</Label>
                    <Controller
                      control={control}
                      name="macAddress"
                      rules={{
                        validate(number: string | null) {
                          if (!number) return 'Mac Address is required';
                          if (number?.length < 12) {
                            return 'Valid Mac Address is required';
                          }
                        },
                      }}
                      render={({ field, fieldState }) => {
                        return (
                          <>
                            <PatternFormat
                              disabled={isReadOnly}
                              customInput={Input}
                              value={field.value}
                              className={`${fieldState?.error ? 'is-invalid' : ''}`}
                              data-testid="macAddress"
                              onChange={e => field.onChange(e)}
                              format="##-##-##-##-##-##"
                              mask="_"
                              placeholder="__-__-__-__-__-__"
                            />
                            <FormFeedback>{fieldState?.error && fieldState?.error?.message}</FormFeedback>
                          </>
                        );
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }} className="flex-column">
                    <Label for="organizationId">{'Organization*'}</Label>
                    <InputGroup className="mb-3" error={!!errors?.org} data-testid="organizationId" data-cy="organizationId">
                      <Controller
                        control={control}
                        name="org"
                        rules={{
                          required: 'Organization is required',
                        }}
                        render={({ field, fieldState }) => {
                          return (
                            <>
                              <ReactSelect
                                isDisabled={isReadOnly}
                                menuPlacement="auto"
                                classNamePrefix="org"
                                options={organizations}
                                styles={reactStrapStyles({ error: !!fieldState.error?.message })}
                                value={field.value}
                                onChange={(option: any) => {
                                  field.onChange(option);
                                }}
                              />
                            </>
                          );
                        }}
                      />
                      <FormFeedback>{errors?.organizationId?.message as string}</FormFeedback>
                    </InputGroup>
                  </div>
                  <ValidatedField
                    label={translate('rainspotApp.device.imeiNo')}
                    id="device-imeiNo"
                    name="imeiNo"
                    data-cy="imeiNo"
                    type="text"
                    disabled={isReadOnly}
                    register={register}
                    error={errors?.imeiNo as FieldError}
                    validate={{
                      required: { value: false, message: translate('entity.validation.required') },
                    }}
                  />
                </div>

                <div className="d-flex">
                  <ValidatedField
                    label={translate('rainspotApp.device.microControllerSerialNo')}
                    id="device-microControllerSerialNo"
                    name="microControllerSerialNo"
                    data-cy="microControllerSerialNo"
                    type="text"
                    disabled={isReadOnly}
                    register={register}
                    error={errors?.microControllerSerialNumber as FieldError}
                    validate={{
                      required: { value: false, message: translate('entity.validation.required') },
                    }}
                  />
                  <ValidatedField
                    label={translate('rainspotApp.device.microControllerFwNo')}
                    id="device-microControllerFwNo"
                    name="microControllerFwNo"
                    data-cy="microControllerFwNo"
                    type="text"
                    disabled={isReadOnly}
                    register={register}
                    error={errors?.microControllerFwNo as FieldError}
                    validate={{
                      required: { value: false, message: translate('entity.validation.required') },
                    }}
                  />
                  <ValidatedField
                    label={translate('rainspotApp.device.wiSunSerialNo')}
                    id="device-wiSunSerialNo"
                    name="wiSunSerialNo"
                    data-cy="wiSunSerialNo"
                    type="text"
                    disabled={isReadOnly}
                    register={register}
                    error={errors?.wiSunSerialNo as FieldError}
                    validate={{
                      required: { value: false, message: translate('entity.validation.required') },
                    }}
                  />
                </div>

                <div className="d-flex">
                  <ValidatedField
                    label={translate('rainspotApp.device.wiSunFwNo')}
                    id="device-wiSunFwNo"
                    name="wiSunFwNo"
                    data-cy="wiSunFwNo"
                    type="text"
                    disabled={isReadOnly}
                    register={register}
                    error={errors?.wiSunFwNo as FieldError}
                    validate={{
                      required: { value: false, message: translate('entity.validation.required') },
                    }}
                  />
                  <ValidatedField
                    label={translate('rainspotApp.device.cellModuleSerialNo')}
                    id="device-cellModuleSerialNo"
                    name="cellModuleSerialNo"
                    data-cy="cellModuleSerialNo"
                    type="text"
                    disabled={isReadOnly}
                    register={register}
                    error={errors?.cellModuleSerialNo as FieldError}
                    validate={{
                      required: { value: false, message: translate('entity.validation.required') },
                    }}
                  />
                  <ValidatedField
                    label={translate('rainspotApp.device.cellModuleFwNo')}
                    id="device-cellModuleFwNo"
                    name="cellModuleFwNo"
                    data-cy="cellModuleFwNo"
                    type="text"
                    disabled={isReadOnly}
                    register={register}
                    error={errors?.cellModuleFwNo as FieldError}
                    validate={{
                      required: { value: false, message: translate('entity.validation.required') },
                    }}
                  />
                </div>
              </div>
              <hr />
              <div className="justify-content-end d-flex form-footer">
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/inventory" replace color="info">
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                {!isReadOnly && (
                  <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                    <Translate contentKey="entity.action.save">Save</Translate>
                  </Button>
                )}
              </div>
            </form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default InventoryUpdate;
