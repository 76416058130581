import React from 'react';
import Loadable from 'react-loadable';
import { Navigate } from 'react-router-dom';

import { AUTHORITIES, PERMISSIONS } from 'app/config/constants';
import EntitiesRoutes from 'app/entities/routes';
import Activate from 'app/modules/account/activate/activate';
import { PasswordResetFinishPage } from 'app/modules/account/password-reset/finish/password-reset-finish';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import Register from 'app/modules/account/register/register';
import Login from 'app/modules/login/login';
import Support from 'app/modules/support/support';
import Logout from 'app/modules/login/logout';
import PrivateRoute from 'app/shared/auth/private-route';
import PageNotFound from 'app/shared/error/page-not-found';
import Terms from 'app/modules/terms/terms';
import { useAppSelector } from './config/store';

const loading = <div>loading ...</div>;

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => loading,
});

const LandingPage = () => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const account = useAppSelector(state => state.authentication.account);
  const auth = isAuthenticated;

  if (auth && (!account?.permissions || account?.permissions.length === 0)) {
    return <Navigate to="/account/settings" />;
  }
  return auth ? <Navigate to="/device" /> : <Navigate to="/login" />;
};

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

export const dataroutes = [
  { index: true, element: <LandingPage /> },
  { path: 'login', element: <Login /> },
  { path: 'delete', element: <Support /> },
  { path: 'terms', element: <Terms /> },
  { path: 'logout', element: <Logout /> },
  {
    path: 'account',
    children: [
      {
        path: '*',
        element: <Account />,
      },
      { path: 'register', element: <Register /> },
      { path: 'activate', element: <Activate /> },
      {
        path: 'reset',
        children: [
          { path: 'request', element: <PasswordResetInit /> },
          { path: 'finish', element: <PasswordResetFinishPage /> },
        ],
      },
    ],
  },
  {
    path: 'admin/*',
    element: (
      <PrivateRoute hasAnyPermissions={[PERMISSIONS.ALL]}>
        <Admin />
      </PrivateRoute>
    ),
  },
  {
    path: '*',
    element: <EntitiesRoutes />,
  },
  { path: '*', element: <PageNotFound /> },
];
