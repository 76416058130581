import React, { useEffect, useState, createContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, updateEntity, createEntity, reset } from './organization.reducer';

import ApplicationUser from '../application-user/application-user';
import { getEntities as getStates } from 'app/entities/city/state.reducer';
import { getEntities as getCityEntitiesByState } from 'app/entities/city/city.reducer';
import { OrganizationForm } from './organization-form';
import ApplicationUserUpdate from '../application-user/application-user-update';
import { hasAnyAuthority, hasPermissions } from 'app/shared/auth/private-route';
import { AUTHORITIES, PERMISSIONS } from 'app/config/constants';
import InviteUser from '../application-user/invite-user';
import { getFilterByState } from 'app/shared/util/form-utils';

export const OrganizationContext = createContext<any>(null);

export const OrganizationUpdate = () => {
  const dispatch = useAppDispatch();
  const [activeTabId, setActiveTab] = useState('tab-details');
  const [organizationUser, setOrganizationUser] = useState(null);
  const [mode, setMode] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const organizationEntity = useAppSelector(state => state.organization.entity);
  const loading = useAppSelector(state => state.organization.loading);
  const updating = useAppSelector(state => state.organization.updating);
  const updateSuccess = useAppSelector(state => state.organization.updateSuccess);
  const states = useAppSelector(state => state.states.menuOptions);
  const cities = useAppSelector(state => state.city.menuOptions);
  const permissions = useAppSelector(state => state.authentication.account.permissions);
  const canAccessOrgUsers = hasPermissions(permissions, [PERMISSIONS.USER_EDIT, PERMISSIONS.USER_READ, PERMISSIONS.ALL]);

  const handleClose = () => {
    navigate('/organization' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getStates({}));
  }, []);

  useEffect(() => {
    if (organizationEntity?.id) {
      const filterUrl = getFilterByState(organizationEntity.state);
      dispatch(getCityEntitiesByState({ filterUrl }));
    }
  }, [organizationEntity]);

  useEffect(() => {
    if (updateSuccess && isNew) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    values.dateCreated = convertDateTimeToServer(values.dateCreated);
    values.dateUpdated = convertDateTimeToServer(values.dateUpdated);

    const entity = {
      ...organizationEntity,
      ...values,
      city: values?.city?.value,
      state: values?.state?.value,
      status: values?.status?.value,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  return (
    <div className="tabs-layout">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTabId === 'tab-details' ? 'active' : ''}
            onClick={function noRefCheck() {
              if (activeTabId !== 'tab-details') {
                setMode(null);
              }
              setActiveTab('tab-details');
            }}
          >
            Details
          </NavLink>
        </NavItem>
        {canAccessOrgUsers && (
          <NavItem>
            <NavLink
              className={activeTabId === 'tab-users' ? 'active' : ''}
              onClick={function noRefCheck() {
                if (activeTabId !== 'tab-users') {
                  setMode(null);
                }
                setActiveTab('tab-users');
              }}
            >
              Users
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTabId}>
        <TabPane tabId="tab-details">
          <OrganizationForm
            states={states}
            cities={cities}
            organizationEntity={organizationEntity}
            isNew={isNew}
            saveEntity={saveEntity}
            loading={loading}
            updating={updating}
          />
        </TabPane>
        {canAccessOrgUsers && (
          <TabPane tabId="tab-users">
            <OrganizationContext.Provider value={{ id, setOrganizationUser, organizationUser, mode, setMode }}>
              {mode === 'new' ? <InviteUser /> : !mode ? <ApplicationUser /> : <ApplicationUserUpdate />}
            </OrganizationContext.Provider>
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

export default OrganizationUpdate;
