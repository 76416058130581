import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import { PERMISSIONS } from 'app/config/constants';
import Report from 'app/modules/account/cypress-report/report';
import PrivateRoute from 'app/shared/auth/private-route';
import ApplicationUser from './application-user';
import City from './city';
import Comment from './comment';
import ComputedMetric from './computed-metric';
import Device from './device';
import DevicePayload from './device-payload';
import DeviceReportedMetric from './device-reported-metric';
import Hive from './hive';
import Inventory from './inventory';
import Maps from './maps';
import Messages from './messages';
import Metric from './metric';
import Neighborhood from './neighborhood';
import Organization from './organization';
import Roles from './roles';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route
          path="comment/*"
          element={
            <PrivateRoute hasAnyPermissions={[PERMISSIONS.ALL]}>
              <Comment />
            </PrivateRoute>
          }
        />
        <Route
          path="application-user/*"
          element={
            <PrivateRoute hasAnyPermissions={[PERMISSIONS.ALL, PERMISSIONS.USER_EDIT, PERMISSIONS.USER_READ]}>
              <ApplicationUser />
            </PrivateRoute>
          }
        />
        <Route
          path="inventory/*"
          element={
            <PrivateRoute hasAnyPermissions={[PERMISSIONS.INVENTORY_EDIT, PERMISSIONS.INVENTORY_READ, PERMISSIONS.ALL]}>
              <Inventory />
            </PrivateRoute>
          }
        />
        <Route
          path="city/*"
          element={
            <PrivateRoute hasAnyPermissions={[PERMISSIONS.ALL]}>
              <City />
            </PrivateRoute>
          }
        />
        <Route
          path="organization/*"
          element={
            <PrivateRoute hasAnyPermissions={[PERMISSIONS.ORGANIZATION_EDIT, PERMISSIONS.ORGANIZATION_READ, PERMISSIONS.ALL]}>
              <Organization />
            </PrivateRoute>
          }
        />
        <Route
          path="metric/*"
          element={
            <PrivateRoute hasAnyPermissions={[PERMISSIONS.METRIC_EDIT, PERMISSIONS.METRIC_READ, PERMISSIONS.ALL]}>
              <Metric />
            </PrivateRoute>
          }
        />
        <Route
          path="device-reported-metric/*"
          element={
            <PrivateRoute hasAnyAuthorities={[PERMISSIONS.ALL]}>
              <DeviceReportedMetric />
            </PrivateRoute>
          }
        />
        <Route
          path="computed-metric/*"
          element={
            <PrivateRoute hasAnyAuthorities={[PERMISSIONS.ALL]}>
              <ComputedMetric />
            </PrivateRoute>
          }
        />
        {/* <Route path="mesh/*" element={<Mesh />} /> */}
        <Route
          path="maps/*"
          element={
            <PrivateRoute hasAnyPermissions={[PERMISSIONS.MAPS_EDIT, PERMISSIONS.MAPS_READ, PERMISSIONS.ALL]}>
              <Maps />
            </PrivateRoute>
          }
        />
        <Route
          path="messages/*"
          element={
            <PrivateRoute hasAnyPermissions={[PERMISSIONS.MESSAGES_EDIT, PERMISSIONS.MESSAGES_READ, PERMISSIONS.ALL]}>
              <Messages />
            </PrivateRoute>
          }
        />
        <Route
          path="hive/*"
          element={
            <PrivateRoute hasAnyPermissions={[PERMISSIONS.HIVE_EDIT, PERMISSIONS.HIVE_READ, PERMISSIONS.ALL]}>
              <Hive />
            </PrivateRoute>
          }
        />
        <Route
          path="device/*"
          element={
            <PrivateRoute hasAnyPermissions={[PERMISSIONS.DEVICE_EDIT, PERMISSIONS.DEVICE_READ, PERMISSIONS.ALL]}>
              <Device />
            </PrivateRoute>
          }
        />
        <Route
          path="device-payload/*"
          element={
            <PrivateRoute hasAnyPermissions={[PERMISSIONS.ALL]}>
              <DevicePayload />
            </PrivateRoute>
          }
        />
        <Route
          path="access-control/*"
          element={
            <PrivateRoute hasAnyPermissions={[PERMISSIONS.ALL]}>
              <Roles />
            </PrivateRoute>
          }
        />
        <Route
          path="cypressReport/*"
          element={
            <PrivateRoute hasAnyPermissions={[PERMISSIONS.ALL]}>
              <Report />
            </PrivateRoute>
          }
        />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
