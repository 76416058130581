import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useMemo, useState } from 'react';
import { log, Translate, translate, ValidatedField } from 'react-jhipster';
import { Button, Col, Row } from 'reactstrap';

import { createColumnHelper } from '@tanstack/react-table';
import ReactTable from 'app/modules/common/table';
import ExpandIcon from 'app/shared/icons/expand-icon';
import { FieldError, useForm } from 'react-hook-form';
import { createPermissions, getAuthorityPermissions, getEntities, reset, updatePermission } from '../permissions/permissions.reducer';
import { mapformValuesToPermissions, mapPermissionsToFormValues, mapPermissionsToHierarchy, PermissionsType } from './utils';
import UnExpandIcon from 'app/shared/icons/un-expand-icon';

export const PermissionsGrid = ({
  selectedRowId,
  setSelectedRowId,
  setIsNew,
}: {
  selectedRowId?: number | null;
  setSelectedRowId?: (id: number | null) => void;
  setIsNew: (isNew: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.device.loading);
  const permissionsArray = useAppSelector(state => state.permissions.entities);
  const authorityPermissions = useAppSelector(state => state.permissions.authorityPermissions);
  const createPermissionSuccess = useAppSelector(state => state.permissions.createPermissionSuccess);
  const totalItems = useAppSelector(state => state.permissions.totalItems);
  const columnHelper = createColumnHelper();
  const [expanded, setExpanded] = useState({});
  const [hideAll, setHideAll] = useState(false);
  const [readAll, setReadAll] = useState(false);
  const [editAll, setEditAll] = useState(false);
  const [permissions, setPermissions] = useState<PermissionsType[] | []>([]);
  useEffect(() => {
    if (createPermissionSuccess) {
      dispatch(reset());
      setIsNew(false);
    }
  }, [createPermissionSuccess]);

  const getAllEntities = () => {
    dispatch(getEntities());
  };

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    getValues,
    reset: resetForm,
    watch,
  } = useForm();
  const values = watch();

  useEffect(() => {
    if (selectedRowId) {
      dispatch(getAuthorityPermissions(selectedRowId));
    } else {
      dispatch(reset());
    }
  }, [selectedRowId]);

  useEffect(() => {
    const rolePermissionsArray = mapPermissionsToFormValues(
      authorityPermissions ? authorityPermissions : [],
      permissionsArray,
      selectedRowId,
    );
    resetForm(rolePermissionsArray);
    setExpanded({});
  }, [authorityPermissions, resetForm, permissionsArray]);

  useEffect(() => {
    const allRows = permissions.flatMap(p => [p, ...(p?.subRows || [])]);
    // Check if ALL rows have HIDE selected
    const allHidden = allRows.every(row => getValues(`${row.key}_HIDE`) === 'on');
    setHideAll(allHidden);

    // Check if ALL rows have READ selected
    const allRead = allRows.every(row => getValues(`${row.key}_READ`) === 'on');
    setReadAll(allRead);

    // Check if ALL rows have EDIT selected
    const allEdit = allRows.every(row => getValues(`${row.key}_EDIT`) === 'on');
    setEditAll(allEdit);
  }, [values]); // Add values from watch() as dependency

  useEffect(() => {
    getAllEntities();
  }, []);

  const columns = useMemo(() => {
    const handleHeaderCheckbox = (permission: string) => {
      const allRows = permissions.flatMap(p => [p, ...(p?.subRows || [])]);
      switch (permission) {
        case 'HIDE':
          setHideAll(!hideAll);
          allRows.forEach(row => {
            setValue(`${row.key}_HIDE`, !hideAll ? 'on' : 'off');
            if (!hideAll) {
              setValue(`${row.key}_READ`, 'off');
              setValue(`${row.key}_EDIT`, 'off');
            }
          });
          break;
        case 'READ':
          setReadAll(!readAll);
          allRows.forEach(row => {
            setValue(`${row.key}_READ`, !readAll ? 'on' : 'off');
            if (!readAll) {
              setValue(`${row.key}_HIDE`, 'off');
              setValue(`${row.key}_EDIT`, 'off');
            }
          });
          break;
        case 'EDIT':
          setEditAll(!editAll);
          allRows.forEach(row => {
            setValue(`${row.key}_EDIT`, !editAll ? 'on' : 'off');
            if (!editAll) {
              setValue(`${row.key}_HIDE`, 'off');
              setValue(`${row.key}_READ`, 'off');
            }
          });
          break;
      }
    };
    const handleRadioChange = (row, permission) => {
      const rowValue = row?.key;

      if (row) {
        // Handle subRows if permission is HIDE
        if (permission === 'HIDE' && row.subRows) {
          row.subRows.forEach(subrow => {
            ['HIDE', 'EDIT', 'READ'].forEach(action => setValue(`${subrow?.value}_${action}`, action === 'HIDE' ? 'on' : 'off'));
          });
        }

        const parentValue = row?.parent ? rowValue?.split('.')[0] : null;
        const parentHide = parentValue ? getValues(`${parentValue}_HIDE`) === 'on' : null;

        // Set values based on parent's HIDE state or permission
        const hideValue = parentHide || permission === 'HIDE' ? 'on' : 'off';
        const readValue = !parentHide && permission === 'READ' ? 'on' : 'off';
        const editValue = !parentHide && permission === 'EDIT' ? 'on' : 'off';

        ['HIDE', 'READ', 'EDIT'].forEach(action => {
          const value = action === 'HIDE' ? hideValue : action === 'READ' ? readValue : editValue;
          setValue(`${rowValue}_${action}`, value);
        });
      }
    };

    return [
      columnHelper.accessor('label', {
        header: 'SECTIONS',
        cell(col: any) {
          const { row } = col;
          const isParent = row?.original?.subRows?.length > 0;
          return (
            <div
              data-testid="expandable-icon-div"
              style={{
                paddingLeft: `${row.depth * 1.5}rem`,
              }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <div className="d-flex">
                {isParent ? (
                  <div
                    className="d-flex"
                    data-testid="expandable-icon"
                    {...{
                      onClick() {
                        setExpanded({ ...expanded, [row.id]: !row.getIsExpanded() });
                      },
                    }}
                  >
                    {isParent && (
                      <>
                        <span data-testid="expansion-toggle" id="expansion-toggle">
                          {expanded[row.id] ? <UnExpandIcon /> : <ExpandIcon />}
                        </span>
                      </>
                    )}
                    <div className="permissionName" style={!isParent ? { marginLeft: '12px' } : {}}>
                      {row?.original?.description}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className={'permissionName'} style={{ marginLeft: row?.depth > 0 ? '1rem' : '2.4rem' }}>
                      {row?.original?.description}
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        },
        size: 150,
        meta: { index: 0 },
      }),
      columnHelper.accessor('hide', {
        header: () => (
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              className={'permissions-check'}
              checked={hideAll}
              style={{
                accentColor: 'white',
                cursor: 'pointer',
              }}
              onChange={() => handleHeaderCheckbox('HIDE')}
            />
            <div style={{ marginLeft: '5px', marginTop: '2px' }}>{translate('rainspotApp.roles.hide')}</div>
          </div>
        ),
        cell(col: any) {
          const { row } = col;
          return (
            <ValidatedField
              id="role-name"
              name={row.original?.key + '_HIDE'}
              data-cy="hide"
              type="radio"
              register={register}
              onChange={() => handleRadioChange(row.original, 'HIDE')}
            />
          );
        },
      }),
      columnHelper.accessor('read', {
        header: () => (
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              className={'permissions-check'}
              data-cy="all-permissions-check-read"
              checked={readAll}
              style={{
                accentColor: 'white',
                cursor: 'pointer',
              }}
              onChange={() => handleHeaderCheckbox('READ')}
            />
            <div style={{ marginLeft: '5px', marginTop: '2px' }}>{translate('rainspotApp.roles.read')}</div>
          </div>
        ),
        cell(col: any) {
          const { row } = col;
          return (
            <ValidatedField
              id="role-name"
              name={row.original?.key + '_READ'}
              data-cy="hide"
              type="radio"
              register={register}
              onChange={() => handleRadioChange(row.original, 'READ')}
            />
          );
        },
      }),
      columnHelper.accessor('edit', {
        header: () => (
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              className={'permissions-check'}
              data-cy="all-permissions-check-edit"
              checked={editAll}
              style={{
                accentColor: 'white',
                cursor: 'pointer',
              }}
              onChange={() => handleHeaderCheckbox('EDIT')}
            />
            <div style={{ marginLeft: '5px', marginTop: '2px' }}>{translate('rainspotApp.roles.edit')}</div>
          </div>
        ),
        cell(col: any) {
          const { row } = col;
          return (
            <ValidatedField
              id="role-name"
              name={row.original?.key + '_EDIT'}
              data-cy="edit"
              type="radio"
              register={register}
              onChange={() => handleRadioChange(row.original, 'EDIT')}
            />
          );
        },
      }),
    ];
  }, [setExpanded, expanded, hideAll, setHideAll, editAll, setEditAll, readAll, setReadAll]);

  const saveEntity = (values: any) => {
    const entity = {
      ...values,
    };
    const val = mapformValuesToPermissions(entity, permissionsArray);
    dispatch(!selectedRowId ? createPermissions(val) : updatePermission(val));
    resetForm();
    setSelectedRowId(null);
  };

  useEffect(() => {
    const mappedArray = mapPermissionsToHierarchy(permissionsArray);
    setPermissions(mappedArray);
  }, [permissionsArray]);
  return (
    <form onSubmit={handleSubmit(saveEntity)}>
      <Row>
        <Row>
          <Col md="2">
            <ValidatedField
              label={translate('rainspotApp.roles.role') + '*'}
              id="role-name"
              name="roleName"
              data-cy="roleName"
              type="text"
              required
              disabled={selectedRowId ? true : false}
              register={register}
              error={errors?.roleName as FieldError}
              validate={{
                required: { value: true, message: translate('entity.validation.required') },
                pattern: {
                  value: /^\S*$/,
                  message: 'Spaces between characters are not allowed',
                },
              }}
            />
          </Col>
        </Row>
        <Col md="8">
          <ReactTable
            sortingDisabled={true}
            columns={columns}
            data={permissions}
            isLoading={loading}
            isExpandible={true}
            expandedState={expanded}
          ></ReactTable>
        </Col>
      </Row>
      <div className="justify-content-end d-flex form-footer">
        <Button
          id="cancel-save"
          data-cy="entityCreateCancelButton"
          onClick={() => {
            setSelectedRowId(null);
            setIsNew(false);
          }}
          color="info"
        >
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </span>
        </Button>
        {!['SUPER_ADMIN', 'DEFAULT_ROLE'].includes(selectedRowId?.toString()) && (
          <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={false}>
            <Translate contentKey="entity.action.save">Save</Translate>
          </Button>
        )}
      </div>
    </form>
  );
};
export default PermissionsGrid;
