import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useMemo, useState } from 'react';
import { getPaginationState, translate } from 'react-jhipster';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { deleteEntity, getEntities, reset } from '../authorities/authorities.reducer';

import { createColumnHelper } from '@tanstack/react-table';
import ReactTable from 'app/modules/common/table';
import DeleteIcon from 'app/shared/icons/delete-icon';
import { useColumnFiltersQueryString } from 'app/shared/util/table-utils';
import { ROLE_FILTER_QUERY } from './utils';

export const RolesGrid = ({ selectedRowId, setSelectedRowId }) => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id', 'desc'), pageLocation.search),
  );

  const authoritiesOption = useAppSelector(state => state.authorities.entities);
  const loading = useAppSelector(state => state.authorities.loading);
  const totalItems = useAppSelector(state => state.authorities.totalItems);
  const columnHelper = createColumnHelper();
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);

  const { columnFilters, setColumnFilters, queryStringWithPagination } = useColumnFiltersQueryString({
    queryStringAPIFilterKeys: ROLE_FILTER_QUERY,
    pagination: paginationState,
    setPagination: setPaginationState,
  });

  const getAllEntities = () => {
    dispatch(
      getEntities({
        filterUrl: queryStringWithPagination,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };
  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, columnFilters]);

  const handleDelete = val => {
    setSelectedRowId(null);
    dispatch(deleteEntity(val));
  };

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: translate('rainspotApp.roles.name'),
        cell: (info: any) => {
          const rowId = info.row.original?.name;
          const canDelete = !(info.row.original?.systemRole || info.row.original?.defaultRole);
          return (
            <div
              data-cy={`role-row-${rowId}`}
              onMouseEnter={() => setHoveredRowId(rowId)}
              onMouseLeave={() => setHoveredRowId(null)}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <span data-cy={`role-name-${rowId}`}>{info.getValue()}</span>
              {hoveredRowId === rowId && canDelete && (
                <div>
                  <DeleteIcon
                    testId={'role-delete-button'}
                    onClick={e => {
                      e.stopPropagation();
                      handleDelete(info.row.original.name);
                    }}
                  />
                </div>
              )}
            </div>
          );
        },
      }),
    ];
  }, [authoritiesOption, hoveredRowId]);

  return (
    <Row>
      <Col md="8">
        <ReactTable
          columns={columns}
          data={authoritiesOption}
          totalItems={totalItems}
          handlePagination={handlePagination}
          paginationState={paginationState}
          setPaginationState={setPaginationState}
          isLoading={loading}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          onRowClick={(e, row) => {
            setSelectedRowId(row.original.name);
          }}
        ></ReactTable>
      </Col>
    </Row>
  );
};

export default RolesGrid;
