import React from 'react';

function ExpandIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 15 14" fill="none">
      <rect x="0.25" y="0.25" width="13.5334" height="13.5" rx="1.75" stroke="#718096" stroke-width="0.5" />
      <path d="M6.26064 10.3291L9.59768 7L6.26064 3.67091L5.43384 4.49575L7.94407 7L5.43384 9.50425L6.26064 10.3291Z" fill="#A0AEC0" />
    </svg>
  );
}

export default ExpandIcon;
