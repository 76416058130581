export const AUTHORITIES = {
  SUPERADMIN: 'SUPER_ADMIN',
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  UTILITY_ADMIN: 'ROLE_UTILITY_ADMIN',
  UTILITY_USER: 'ROLE_UTILITY_USER',
  DEVICE_OWNER: 'DEVICE_OWNER',
};

export const ACCESS_LEVEL = {
  ALL: 'ALL',
  ORG: 'organization',
  USER: 'user',
};


export const PERMISSIONS = {
  ALL: 'ALL',
  DEVICE_READ: 'DEVICE.READ',
  DEVICE_EDIT: 'DEVICE.EDIT',
  DEVICE_CONFIGURATION_READ: 'DEVICE.CONFIGURATION.READ',
  DEVICE_CONFIGURATION_EDIT: 'DEVICE.CONFIGURATION.EDIT',
  DEVICE_PAYLOAD_READ: 'DEVICE.PAYLOAD.READ',
  DEVICE_PAYLOAD_EDIT: 'DEVICE.PAYLOAD.EDIT',
  DEVICE_LOCATION_READ: 'DEVICE.LOCATION.READ',
  DEVICE_LOCATION_EDIT: 'DEVICE.LOCATION.EDIT',
  DEVICE_MESSAGES_READ: 'DEVICE.MESSAGES.READ',
  DEVICE_MESSAGES_EDIT: 'DEVICE.MESSAGES.EDIT',
  HIVE_READ: 'HIVE.READ',
  HIVE_EDIT: 'HIVE.EDIT',
  MAPS_READ: 'MAPS.READ',
  MAPS_EDIT: 'MAPS.EDIT',
  MESSAGES_READ: 'MESSAGES.READ',
  MESSAGES_EDIT: 'MESSAGES.EDIT',
  INVENTORY_READ: 'INVENTORY.READ',
  INVENTORY_EDIT: 'INVENTORY.EDIT',
  ORGANIZATION_READ: 'ORGANIZATION.READ',
  ORGANIZATION_EDIT: 'ORGANIZATION.EDIT',
  USER_READ: 'USERS.READ',
  USER_EDIT: 'USERS.EDIT',
  NEIGHBORHOOD_READ: 'NEIGHBORHOOD.READ',
  NEIGHBORHOOD_EDIT: 'NEIGHBORHOOD.EDIT',
  METRIC_READ: 'METRIC.READ',
  METRIC_EDIT: 'METRIC.EDIT',
  SETUP_DEVICE_EDIT: 'DEVICE.SETUP_DEVICE.EDIT',
  INVENTORY_ADD_DEVICE_EDIT: 'INVENTORY.ADD_DEVICE.EDIT',
  ORGANIZATION_ADD_ORG_EDIT: 'ORGANIZATION.ADD_ORG.EDIT',
  USER_INVITE_USER_EDIT: 'USERS.INVITE_USER.EDIT'
};


export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'MM/DD/YY HH:mm';
export const APP_DATE_FORMAT_DEFAULT = 'MM/DD/YY';
export const APP_DATE_FORMAT_DATE_PICKER = 'YYYY-MM-DD';
export const APP_DATE_FORMAT_MAP = 'DD-MM-YYYY';
export const APP_TIMESTAMP_FORMAT = 'MM/DD/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
