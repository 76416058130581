import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { IAuthorities, defaultValue } from 'app/shared/model/authorities.model';
import { IPermissions } from 'app/shared/model/permissions.model';
import { EntityState, IQueryParams, createEntitySlice, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';

const initialState: EntityState<IPermissions> = {
  loading: false,
  errorMessage: null,
  entities: [],
  createPermissionSuccess: false,
  authorityPermissions: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/permissions';
const authorityPermissions = 'api/authorities-permissions';

// Actions

export const getEntities = createAsyncThunk('permissions/fetch_entity_list', async () => {
  const requestUrl = `${apiUrl}`;
  return axios.get<IAuthorities[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'permissions/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IAuthorities>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'permissions/create_entity',
  async (entity: IAuthorities, thunkAPI) => {
    const result = await axios.post<IAuthorities>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'permissions/update_entity',
  async (entity: IAuthorities, thunkAPI) => {
    const result = await axios.put<IAuthorities>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'permissions/partial_update_entity',
  async (entity: IAuthorities, thunkAPI) => {
    const result = await axios.patch<IAuthorities>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'permissions/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IAuthorities>(requestUrl);
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const createPermissions = createAsyncThunk(
  'permissions/create_entity_permissions',
  async (entity: any[]) => {
    const payload = entity[1]?.permissionId !== undefined ? entity?.slice(1) : [];
    const result = await axios.post<IAuthorities>(`${authorityPermissions}/bulk-create?authorityName=${entity[0]?.authorityName}`, payload);
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updatePermission = createAsyncThunk(
  'permissions/update_entity_permissions',
  async (entity: any[], thunkAPI) => {
    const payload = entity[1]?.permissionId !== undefined ? entity?.slice(1) : [];
    const result = await axios.put<IAuthorities>(`${authorityPermissions}/bulk-update?authorityName=${entity[0]?.authorityName}`, payload);
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const getAuthorityPermissions = createAsyncThunk(
  'permissions/fetch_entity_permissions',
  async (id: string | number) => {
    const requestUrl = `${authorityPermissions}?authorityId.equals=${id}`;
    return axios.get(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

// slice

export const permissionsSlice = createEntitySlice({
  name: 'permissions',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(createPermissions.fulfilled, state => {
        state.updating = false;
        state.createPermissionSuccess = true;
        state.entity = {};
      })
      .addCase(getAuthorityPermissions.fulfilled, (state, action) => {
        state.authorityPermissions = (action.payload as any).data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = permissionsSlice.actions;

// Reducer
export default permissionsSlice.reducer;
