import { ACCESS_LEVEL, AUTHORITIES, PERMISSIONS } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { Translate, getPaginationState, translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Badge, Card } from 'reactstrap';
import { reset } from './inventory.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import ReactTable from 'app/modules/common/table';
import AddLinkIcon from 'app/shared/icons/link-icon';
import { getEntities } from './inventory.reducer';
import { useColumnFiltersQueryString } from 'app/shared/util/table-utils';
import { DEVICE_TABLE_QUERY_KEYS } from './utils';
import { formatDate } from 'app/shared/util/date-utils';
import { hasPermissions } from 'app/shared/auth/private-route';

export const Inventory = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id', 'desc'), pageLocation.search),
  );

  const inventoryList = useAppSelector(state => state.device.entities);
  const loading = useAppSelector(state => state.device.loading);
  const totalItems = useAppSelector(state => state.device.totalItems);
  const accessLevel = useAppSelector(state => state.authentication.account.accessLevel);
  const permissions = useAppSelector(state => state.authentication.account.permissions);
  const applicationUser = useAppSelector(state => state.authentication?.internalUser);
  const columnHelper = createColumnHelper();
  const canSetupDevice = hasPermissions(permissions, [
    PERMISSIONS.ALL,
    PERMISSIONS.INVENTORY_ADD_DEVICE_EDIT,
  ]);
  const { columnFilters, setColumnFilters, queryStringWithPagination } = useColumnFiltersQueryString({
    queryStringAPIFilterKeys: DEVICE_TABLE_QUERY_KEYS,
    pagination: paginationState,
    setPagination: setPaginationState,
  });

  const getAllEntities = () => {
    let filterUrl = '';
    if (accessLevel === ACCESS_LEVEL.ORG && applicationUser?.organization?.id) {
      filterUrl = `orgId.equals=${applicationUser?.organization?.id}&`;
    }

    dispatch(
      getEntities({
        filterUrl: filterUrl + queryStringWithPagination,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };
  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (!applicationUser) {
      return;
    }
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, applicationUser, columnFilters]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const columns = [
    columnHelper.accessor('serialNumber', {
      header: translate('rainspotApp.device.serialNumber'),
    }),
    columnHelper.accessor('macAddress', {
      header: translate('rainspotApp.device.macAddress'),
    }),
    columnHelper.accessor('organization', {
      header: translate('rainspotApp.device.organization'),
      cell(col: any) {
        return <>{col?.row?.original?.organization?.name}</>;
      },
    }),
    columnHelper.accessor('createdDate', {
      header: translate('rainspotApp.device.dateCreated'),
      cell(col: any) {
        return <>{formatDate(col?.row?.original?.createdDate)}</>;
      },
    }),
  ];

  return (
    <Card className="jh-card">
      <h2 id="device-heading" data-cy="DeviceHeading">
        <div className="d-flex justify-content-end">
          {canSetupDevice && (
            <>
              <Link
                to="/inventory/new"
                className={`btn btn-primary jh-create-entity`}
                id="jh-create-entity"
                data-cy="entityCreateButton"
              >
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="rainspotApp.device.home.createLabel">Create new Device</Translate>
              </Link>
              &nbsp;
            </>
          )}
        </div>
      </h2>

      <ReactTable
        columns={columns}
        data={inventoryList}
        totalItems={totalItems}
        handlePagination={handlePagination}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        isLoading={loading}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        onRowClick={(e, row) => {
          const id = row?.original?.id;
          navigate(`/inventory/${id}/edit`);
        }}
      ></ReactTable>
    </Card>
  );
};

export default Inventory;
