// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.privacy-policy {
  max-width: 800px;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.privacy-policy h1,
.privacy-policy h2 {
  text-align: center;
}

.privacy-policy ul {
  list-style-type: disc;
  padding-left: 20px;
}

.contact-info {
  text-align: center;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/terms/terms.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,uCAAA;AACF;;AAEA;;EAEE,kBAAA;AACF;;AAEA;EACE,qBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;AACF","sourcesContent":[".privacy-policy-wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 20px;\n}\n\n.privacy-policy {\n  max-width: 800px;\n  width: 100%;\n  background-color: #fff;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.privacy-policy h1,\n.privacy-policy h2 {\n  text-align: center;\n}\n\n.privacy-policy ul {\n  list-style-type: disc;\n  padding-left: 20px;\n}\n\n.contact-info {\n  text-align: center;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
