import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import DeviceConfiguration from './configuration/device-tab-configuration';
import DeviceLocation from './device-location';
import DeviceHealth from './health/device-tab-health';
import DeviceMetrics from './metric/device-tab-metrics';
import DevicePayload from './payload/device-tab-payload';
import DeviceMessages from './messages/device-tab-messages';
import { hasPermissions } from 'app/shared/auth/private-route';
import { PERMISSIONS } from 'app/config/constants';

export const DeviceUpdateTabs = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get('tab');
  const permissions = useAppSelector(state => state.authentication.account.permissions);
  const showConfiguration = hasPermissions(permissions, [
    PERMISSIONS.ALL,
    PERMISSIONS.DEVICE_CONFIGURATION_EDIT,
    PERMISSIONS.DEVICE_CONFIGURATION_READ,
  ]);
  const showPayload = hasPermissions(permissions, [PERMISSIONS.ALL, PERMISSIONS.DEVICE_PAYLOAD_EDIT, PERMISSIONS.DEVICE_PAYLOAD_READ]);
  const showLocation = hasPermissions(permissions, [PERMISSIONS.ALL, PERMISSIONS.DEVICE_LOCATION_EDIT, PERMISSIONS.DEVICE_LOCATION_READ]);
  const showMessages = hasPermissions(permissions, [PERMISSIONS.ALL, PERMISSIONS.DEVICE_MESSAGES_EDIT, PERMISSIONS.DEVICE_MESSAGES_READ]);

  const getInitialActiveTab = () => {
    if (showConfiguration) return 'tab-configuration';
    if (showPayload) return 'tab-payload';
    if (showLocation) return 'tab-owner-info';
    if (showMessages) return 'tab-device-messages';
    return '';
  };

  const [activeTabId, setActiveTab] = useState(getInitialActiveTab());

  useEffect(() => {
    if (tabFromUrl !== null) setActiveTab(tabFromUrl);
  }, [tabFromUrl]);

  return (
    <div className="tabs-layout">
      <Nav tabs>
        {/* <NavItem>
          <NavLink
            className={activeTabId === 'tab-metrics' ? 'active' : ''}
            onClick={function noRefCheck() {
              setActiveTab('tab-metrics');
            }}
          >
            Metrics
          </NavLink>
        </NavItem> */}
        {showConfiguration && (
          <NavItem>
            <NavLink
              className={activeTabId === 'tab-configuration' ? 'active' : ''}
              onClick={function noRefCheck() {
                setActiveTab('tab-configuration');
              }}
            >
              Configuration
            </NavLink>
          </NavItem>
        )}
        {/* <NavItem>
          <NavLink
            className={activeTabId === 'tab-health' ? 'active' : ''}
            onClick={function noRefCheck() {
              setActiveTab('tab-health');
            }}
          >
            Health
          </NavLink>
        </NavItem> */}
        {showPayload && (
          <NavItem>
            <NavLink
              className={activeTabId === 'tab-payload' ? 'active' : ''}
              onClick={function noRefCheck() {
                setActiveTab('tab-payload');
              }}
            >
              Payload
            </NavLink>
          </NavItem>
        )}
        {showLocation && (
          <NavItem>
            <NavLink
              className={activeTabId === 'tab-owner-info' ? 'active' : ''}
              onClick={function noRefCheck() {
                setActiveTab('tab-owner-info');
              }}
            >
              Location
            </NavLink>
          </NavItem>
        )}
        {showMessages && (
          <NavItem>
            <NavLink
              className={activeTabId === 'tab-device-messages' ? 'active' : ''}
              onClick={function noRefCheck() {
                setActiveTab('tab-device-messages');
              }}
            >
              Messages
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTabId}>
        {/* <TabPane tabId="tab-metrics">
          <DeviceMetrics />
        </TabPane> */}
        {showConfiguration && (
          <TabPane tabId="tab-configuration">
            <DeviceConfiguration />
          </TabPane>
        )}
        {/* <TabPane tabId="tab-health">
          <DeviceHealth />
        </TabPane> */}
        {showPayload && (
          <TabPane tabId="tab-payload">
            <DevicePayload />
          </TabPane>
        )}
        {showLocation && (
          <TabPane tabId="tab-owner-info">
            <DeviceLocation />
          </TabPane>
        )}
        {showMessages && (
          <TabPane tabId="tab-device-messages" data-cy="deviceMessages">
            <DeviceMessages entity={'device'} />
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

export default DeviceUpdateTabs;
