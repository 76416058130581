import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Translate, ValidatedField, isEmail, translate } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, FormFeedback, Input, InputGroup, Label, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getAuthorities } from 'app/entities/authorities/authorities.reducer';
import { getEntities as getCityEntitiesByState } from 'app/entities/city/city.reducer';
import { getEntities as getStates } from 'app/entities/city/state.reducer';
import { getEntities as getOrganizations } from 'app/entities/organization/organization.reducer';
import { PasswordText } from 'app/modules/account/register/register';
import { handleRegister, reset as resetRegistration } from 'app/modules/account/register/register.reducer';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { convertImageUrltoDataURL, getFilterByState, reactStrapStyles, validateTelePhoneNumber } from 'app/shared/util/form-utils';
import Avatar from 'react-avatar';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { OrganizationContext } from '../organization/organization-update';
import { getEntity, reset, updateApplicationUser } from './application-user.reducer';
import { DEFAULT_ORGANIZATION, activationOptions, mapEntityToFormValues, mapFormValuesToEntity, useDecisionBasedOnRole } from './utils';
import { hasPermissions } from 'app/shared/auth/private-route';
import { PERMISSIONS } from 'app/config/constants';

export const ApplicationUserUpdateWrapper = () => {
  return (
    <Card className="jh-card">
      <ApplicationUserUpdate />
    </Card>
  );
};

export const ApplicationUserUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const organizationContext = useContext(OrganizationContext);
  const {
    id: organizationId,
    setOrganizationUser,
    organizationUser,
    setMode,
  } = organizationContext ?? { id: null, setOrganizationUser: null, organizationUser: null, setMode: null };

  const id = organizationContext ? organizationUser : useParams<'id'>()?.id;
  const isNew = !id;
  const fromOrganization = !!organizationId;

  const organizationOptions = useAppSelector(state => state.organization.menuOptions);
  const authoritiesOption = useAppSelector(state => state.authorities.entities);
  const states = useAppSelector(state => state.states.menuOptions);
  const cities = useAppSelector(state => state.city.menuOptions);
  const applicationUserEntity = useAppSelector(state => state.applicationUser.entity);
  const loading = useAppSelector(state => state.applicationUser.loading);
  const registrationSuccess = useAppSelector(state => state.register.registrationSuccess);
  const [preview, setPreview] = useState(null);
  const [avatar, setAvatar] = useState();

  useEffect(() => {
    if (registrationSuccess) {
      toast.success(translate('register.messages.success'));
      if (!fromOrganization) {
        navigate('/application-user');
      } else {
        setMode(null);
        setOrganizationUser(null);
      }
      dispatch(resetRegistration());
    }
  }, [registrationSuccess]);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
    dispatch(getStates({}));
    dispatch(getOrganizations({}));
    dispatch(getAuthorities({}));
  }, []);

  useEffect(() => {
    if (applicationUserEntity?.id) {
      const filterUrl = getFilterByState(applicationUserEntity?.state);
      dispatch(getCityEntitiesByState({ filterUrl }));
    }
  }, [applicationUserEntity]);

  // eslint-disable-next-line complexity
  const onSubmit = values => {
    dispatch(reset());
    const entity = mapFormValuesToEntity({ values, avatar, preview, applicationUserEntity });

    if (isNew) {
      dispatch(handleRegister(entity));
    } else {
      delete entity?.internalUser;
      delete entity?.organization;
      dispatch(updateApplicationUser(entity));
    }
  };

  return (
    <>
      <Row className="justify-content-start">
        <Col md="12">
          <h2 id="rainspotApp.applicationUser.home.createOrEditLabel" data-cy="ApplicationUserCreateUpdateHeading">
            <Translate contentKey={isNew ? `rainspotApp.applicationUser.home.createLabel` : `rainspotApp.applicationUser.home.editLabel`}>
              Create or edit a ApplicationUser
            </Translate>
          </h2>
        </Col>
      </Row>
      <div className="mt-10">&nbsp;</div>
      <Row className="justify-content-start">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ApplicationUpdateForm
              onSubmit={onSubmit}
              applicationUserEntity={applicationUserEntity}
              isNew={isNew}
              cities={cities}
              organizationOptions={organizationOptions}
              authoritiesOption={authoritiesOption}
              states={states}
              preview={preview}
              setPreview={setPreview}
              setAvatar={setAvatar}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export const ApplicationUpdateForm = ({
  onSubmit,
  applicationUserEntity,
  isNew,
  cities,
  organizationOptions,
  authoritiesOption,
  states,
  preview,
  setPreview,
  setAvatar,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset: resetForm,
    control,
    watch,
    setValue,
    clearErrors,
  } = useForm();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const organizationContext = useContext(OrganizationContext);
  const {
    setMode,
    setOrganizationUser,
    id: organizationId,
  } = organizationContext ?? { setMode: null, setOrganizationUser: null, id: null };
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const watchAuthorities = watch('authorities');
  const permissions = useAppSelector(state => state.authentication.account.permissions);
  const isReadOnly = hasPermissions(permissions, [PERMISSIONS.USER_READ]);

  const { isOrganizationUser, isOrganizationDisabled, isOrganizationRole } = useDecisionBasedOnRole({
    authorities: watchAuthorities,
    organizationId,
  });
  const handleInputClick = e => {
    e.preventDefault();
    fileInputRef.current?.click();
  };
  const defaultValues = useMemo(() => {
    if (authoritiesOption?.length < 1) {
      return;
    }
    return mapEntityToFormValues({
      applicationUserEntity,
      isNew,
      organizationOptions,
      authoritiesOption,
      organizationId,
    });
  }, [applicationUserEntity, organizationOptions?.length, authoritiesOption?.length, organizationId]);

  useEffect(() => {
    if (cities?.length > 0) {
      const city = cities?.find(it => it.value?.toString() === applicationUserEntity?.city?.toString());
      setValue('city', city);
    }
  }, [cities, applicationUserEntity]);

  useEffect(() => {
    resetForm({
      ...defaultValues,
    });
    if (applicationUserEntity?.internalUser?.imageUrl) {
      convertImageUrltoDataURL(applicationUserEntity?.internalUser?.imageUrl)
        .then(dataUrl => {
          setPreview(dataUrl);
        })
        .catch(err => {
          console.error('error in convert Image Url to DataURL', err);
        });
    }
  }, [resetForm, defaultValues]);

  const updatePassword = event => setPassword(event.target.value);

  const handleImageChange = e => {
    const file = e.target.files[0];
    setAvatar(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <form data-testid="application-user-form" onSubmit={vals => void handleSubmit(onSubmit)(vals)}>
      <div className="form-body">
        <Row>
          <Col md="3">
            <Avatar size="72" round={true} src={preview || 'content/images/carbon_user-avatar-filled.svg'} />
            <div className="btn btn-unstyled" onClick={handleInputClick}>
              Upload Profile Picture
            </div>
            <input hidden type="file" ref={fileInputRef} accept="image/*" onChange={handleImageChange} />
          </Col>
        </Row>
        <div className="mt-10">&nbsp;</div>
        <div className="d-flex">
          <ValidatedField
            name="email"
            register={register}
            label={translate('global.form.email.label')}
            error={errors?.email as FieldError}
            type="email"
            disabled={!isNew || isReadOnly}
            validate={{
              required: { value: true, message: translate('global.messages.validate.email.required') },
              minLength: { value: 5, message: translate('global.messages.validate.email.minlength') },
              maxLength: { value: 254, message: translate('global.messages.validate.email.maxlength') },
              validate: v => isEmail(v) || translate('global.messages.validate.email.invalid'),
            }}
            data-cy="email"
          />
        </div>
        <div className="d-flex">
          <ValidatedField
            register={register}
            type="text"
            name="firstName"
            data-cy="firstName"
            error={errors?.firstName as FieldError}
            label={translate('global.form.firstName.label')}
            disabled={isReadOnly}
            validate={{
              required: { value: true, message: translate('register.messages.validate.firstName.required') },
              minLength: { value: 1, message: translate('register.messages.validate.firstName.minlength') },
              maxLength: { value: 50, message: translate('register.messages.validate.firstName.maxlength') },
            }}
          />

          <ValidatedField
            register={register}
            type="text"
            name="lastName"
            data-cy="lastName"
            disabled={isReadOnly}
            error={errors?.lastName as FieldError}
            label={translate('global.form.lastName.label')}
            validate={{
              required: { value: true, message: translate('register.messages.validate.lastName.required') },
              minLength: { value: 1, message: translate('register.messages.validate.lastName.minlength') },
              maxLength: { value: 50, message: translate('register.messages.validate.lastName.maxlength') },
            }}
          />
        </div>
        <div className="d-flex">
          <div style={{ display: 'flex' }} className="flex-column">
            <ValidatedField
              name="password"
              disabled={isReadOnly}
              register={register}
              label={translate('global.form.newpassword.label')}
              type="password"
              className="user-password"
              error={errors?.firstPassword as FieldError}
              onChange={updatePassword}
              validate={{
                required: { value: isNew ? true : false, message: translate('global.messages.validate.newpassword.required') },
                minLength: { value: 4, message: translate('global.messages.validate.newpassword.minlength') },
                maxLength: { value: 50, message: translate('global.messages.validate.newpassword.maxlength') },
              }}
              data-cy="firstPassword"
            />
            <PasswordStrengthBar password={password} />
            <PasswordText style={{ maxWidth: '250px' }} />
          </div>
          <ValidatedField
            name="secondPassword"
            register={register}
            label={translate('global.form.confirmpassword.label')}
            type="password"
            disabled={isReadOnly}
            error={errors?.secondPassword as FieldError}
            validate={{
              required: { value: isNew ? true : false, message: translate('global.messages.validate.confirmpassword.required') },
              minLength: { value: 4, message: translate('global.messages.validate.confirmpassword.minlength') },
              maxLength: { value: 50, message: translate('global.messages.validate.confirmpassword.maxlength') },
              validate: v => v === password || translate('global.messages.error.dontmatch'),
            }}
            data-cy="secondPassword"
          />
        </div>
        <div className="d-flex">
          <div style={{ display: 'flex' }} className="flex-column selectable">
            <Label for="phone">{'Phone'}*</Label>
            <Controller
              control={control}
              name="phone"
              rules={{
                validate(number: string | null) {
                  if (!number) return 'Phone Number is required';
                  if (number?.length < 10) {
                    return 'Valid Phone Number Is required';
                  }
                  return validateTelePhoneNumber(number) || 'Invalid phone number';
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <PatternFormat
                      data-cy="phone"
                      customInput={Input}
                      disabled={isReadOnly}
                      value={field.value}
                      className={`${fieldState?.error ? 'is-invalid' : ''}`}
                      data-testid="phone"
                      onChange={e => field.onChange(e)}
                      format="(###)-###-####"
                      mask="_"
                      placeholder="(___)-___-____"
                    />
                    <FormFeedback>{fieldState?.error && 'Valid Phone Number Is Required'}</FormFeedback>
                  </>
                );
              }}
            />
          </div>
          <div style={{ display: 'flex' }} className="flex-column">
            <Label for="activate">{'Activated'}*</Label>
            <InputGroup className="mb-3" error={!!errors?.activate} data-testid="activated" data-cy="activated">
              <Controller
                control={control}
                name="activated"
                rules={{ required: 'Activated is required' }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <Select
                        classNamePrefix="activated"
                        menuPlacement="auto"
                        isDisabled={isReadOnly}
                        options={activationOptions}
                        styles={reactStrapStyles({ error: !!fieldState.error?.message })}
                        value={field.value}
                        onChange={(file: any) => {
                          field.onChange(file);
                        }}
                      />

                      <FormFeedback>{fieldState.error?.message}</FormFeedback>
                    </>
                  );
                }}
              />
            </InputGroup>
          </div>
        </div>
        <div className="d-flex">
          <div style={{ display: 'flex' }} className="flex-column">
            <Label for="roleId">{'Account type'}*</Label>
            <InputGroup className="mb-3" error={!!errors?.authorities} data-testid="authorities" data-cy="authorities">
              <Controller
                control={control}
                name="authorities"
                rules={{ required: 'Account Type is required' }}
                render={({ field, fieldState }) => {
                  const options =
                    !organizationId && !isOrganizationRole
                      ? authoritiesOption
                      : authoritiesOption.filter(op => op.name === 'ROLE_UTILITY_ADMIN' || op.name === 'ROLE_UTILITY_USER');
                  return (
                    <>
                      <Select
                        classNamePrefix="accountType"
                        menuPlacement="auto"
                        options={options?.map(a => ({ value: a?.name, label: a?.displayName }))}
                        styles={reactStrapStyles({ error: !!fieldState.error?.message })}
                        value={field.value}
                        isDisabled={isOrganizationUser || isReadOnly}
                        onChange={(option: any) => {
                          field.onChange(option);
                          if (option?.value !== 'ROLE_UTILITY_ADMIN' && option?.value !== 'ROLE_UTILITY_USER') {
                            setValue(
                              'organizationId',
                              organizationOptions?.find(o => o?.label?.toString()?.toUpperCase() === DEFAULT_ORGANIZATION),
                            );
                            clearErrors('organizationId');
                          } else {
                            setValue(
                              'organizationId',
                              organizationOptions?.find(
                                o => o?.value?.toString() === applicationUserEntity?.organization?.id?.toString(),
                              ) ?? null,
                            );
                            clearErrors('organizationId');
                          }
                        }}
                      />

                      <FormFeedback>{fieldState.error?.message}</FormFeedback>
                    </>
                  );
                }}
              />
            </InputGroup>
          </div>
          <div style={{ display: 'flex' }} className="flex-column">
            <Label for="organizationId">{'Organization*'}</Label>
            <InputGroup className="mb-3" error={!!errors?.organizationId} data-testid="organizationId" data-cy="organizationId">
              <Controller
                control={control}
                name="organizationId"
                rules={{
                  required: 'Organization is required',
                }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <Select
                        menuPlacement="auto"
                        classNamePrefix="org"
                        options={organizationOptions}
                        styles={reactStrapStyles({ error: !!fieldState.error?.message })}
                        isDisabled={isOrganizationDisabled || isReadOnly}
                        value={field.value}
                        onChange={(option: any) => {
                          field.onChange(option);
                        }}
                      />
                    </>
                  );
                }}
              />
              <FormFeedback>{errors?.organizationId?.message as string}</FormFeedback>
            </InputGroup>
          </div>
        </div>
        <div className="d-flex">
          <ValidatedField
            register={register}
            type="text"
            name="address"
            data-cy="address"
            error={errors?.address as FieldError}
            label={'Address*'}
            disabled={isReadOnly}
            validate={{
              required: { value: true, message: translate('register.messages.validate.address.required') },
              minLength: { value: 1, message: translate('register.messages.validate.address.minlength') },
              maxLength: { value: 100, message: translate('register.messages.validate.address.maxlength') },
            }}
          />
          <div style={{ display: 'flex' }} className="flex-column selectable">
            <Label for="state">{'State'}*</Label>
            <InputGroup className="mb-3" error={!!errors?.state} data-testid={'state'} data-cy="state">
              <Controller
                control={control}
                name="state"
                rules={{ required: 'State is required' }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <Select
                        classNamePrefix="state"
                        menuPlacement="auto"
                        options={states}
                        isDisabled={isReadOnly}
                        styles={reactStrapStyles({ error: !!fieldState.error?.message })}
                        value={field.value}
                        onChange={(file: any) => {
                          field.onChange(file);
                          const filterUrl = getFilterByState(file?.value);
                          dispatch(getCityEntitiesByState({ filterUrl }));
                          setValue('city', null);
                        }}
                      />

                      <FormFeedback>{fieldState.error?.message}</FormFeedback>
                    </>
                  );
                }}
              />
            </InputGroup>
          </div>
        </div>
        <div className="d-flex">
          <div style={{ display: 'flex' }} className="flex-column">
            <Label for="password">{'City'}*</Label>
            <InputGroup className="mb-3" error={!!errors?.city} data-testid={'city'} data-cy="city">
              <Controller
                control={control}
                name="city"
                rules={{ required: 'City is required' }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <Select
                        classNamePrefix="city"
                        menuPlacement="auto"
                        isDisabled={isReadOnly}
                        options={cities}
                        styles={reactStrapStyles({ error: !!fieldState.error?.message })}
                        value={field.value}
                        onChange={(file: any) => {
                          field.onChange(file);
                        }}
                      />

                      <FormFeedback>{fieldState.error?.message}</FormFeedback>
                    </>
                  );
                }}
              />
            </InputGroup>
          </div>
          <ValidatedField
            register={register}
            type="number"
            name="zipcode"
            data-cy="zipcode"
            disabled={isReadOnly}
            error={errors?.zipcode as FieldError}
            label={translate('global.form.zipcode.label')}
            validate={{
              required: { value: true, message: translate('register.messages.validate.zipcode.required') },
              minLength: { value: 1, message: translate('register.messages.validate.zipcode.minlength') },
              maxLength: { value: 16, message: translate('register.messages.validate.zipcode.maxlength') },
            }}
          />
        </div>
      </div>
      <hr className="solid-divider" />
      <div className={`d-flex ${!isNew ? 'justify-content-between' : 'justify-content-end'}`}>
        <div></div>
        <div className="d-flex justify-content-end form-footer">
          <Button
            id="cancel-save"
            data-cy="entityCreateCancelButton"
            onClick={() => {
              if (setOrganizationUser) {
                setMode(null);
              } else {
                navigate('/application-user');
              }
            }}
            replace
            color="info"
          >
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.cancel">Back</Translate>
            </span>
          </Button>
          &nbsp;
          {!isReadOnly && (
            <Button color="primary" id="save-entity" data-testid="save-entity" data-cy="entityCreateSaveButton" type="submit">
              <Translate contentKey="entity.action.save">Save</Translate>
            </Button>
          )}
        </div>
      </div>
    </form>
  );
};
export default ApplicationUserUpdate;
