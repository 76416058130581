import { reactStrapStyles } from 'app/shared/util/form-utils';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Card, FormFeedback, Input, InputGroup, Label } from 'reactstrap';
import { decisionOption, runtimesOptions } from './utils';
import moment from 'moment';
import { useAppSelector } from 'app/config/store';
import { PERMISSIONS } from 'app/config/constants';
import { hasPermissions } from 'app/shared/auth/private-route';

export const Maps = () => {
  const {
    formState: { errors },
    reset: resetForm,
    control,
    watch,
  } = useForm();

  useEffect(() => {
    resetForm({
      decision: decisionOption[0],
      runtime: runtimesOptions[0],
      date: moment(new Date()).format('YYYY-MM-DD'),
    });
  }, []);

  const watchDecision = watch('decision')?.value;
  const watchDate = watch('date');
  const watchRuntime = watch('runtime')?.value;
  const [isError, setError] = useState(false);

  const permissions = useAppSelector(state => state.authentication.account.permissions);
  const isReadOnly = hasPermissions(permissions, [PERMISSIONS.MESSAGES_READ]);

  const renderGraphFilter = () => {
    return (
      <form>
        <div className="d-flex flex-row gap-2">
          <div style={{ display: 'flex' }} className="flex-column">
            <Label for="decision">{'Decision'}</Label>
            <InputGroup className="mb-3" error={errors?.activate} data-testid="decision" data-cy="decision">
              <Controller
                control={control}
                name="decision"
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <Select
                        menuPlacement="auto"
                        isDisabled={isReadOnly}
                        classNamePrefix="decision"
                        options={decisionOption}
                        styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                        value={field.value}
                        onChange={(file: any) => {
                          field.onChange(file);
                        }}
                      />

                      <FormFeedback>{fieldState.error?.message}</FormFeedback>
                    </>
                  );
                }}
              />
            </InputGroup>
          </div>

          <div style={{ display: 'flex' }} className="flex-column">
            <Label for="runtime">{'Runtime'}</Label>
            <InputGroup className="mb-3" error={errors?.activate} data-testid="runtime" data-cy="runtime">
              <Controller
                control={control}
                name="runtime"
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <Select
                        menuPlacement="auto"
                        classNamePrefix="runtime"
                        isDisabled={isReadOnly}
                        options={runtimesOptions}
                        styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                        value={field.value}
                        onChange={(file: any) => {
                          field.onChange(file);
                        }}
                      />

                      <FormFeedback>{fieldState.error?.message}</FormFeedback>
                    </>
                  );
                }}
              />
            </InputGroup>
          </div>

          <div style={{ display: 'flex' }} className="flex-column">
            <Label for="date">{'Date'}</Label>
            <InputGroup style={{ width: '245px' }} className="mb-3" error={errors?.date} data-testid="date" data-cy="date">
              <Controller
                control={control}
                name="date"
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <Input
                        type="date"
                        data-testid="dateInput"
                        value={field.value}
                        disabled={isReadOnly}
                        onChange={(e: any) => {
                          field.onChange(e.target.value);
                        }}
                      />

                      <FormFeedback>{fieldState.error?.message}</FormFeedback>
                    </>
                  );
                }}
              />
            </InputGroup>
          </div>
        </div>
      </form>
    );
  };

  const getGraphFilterResult = () => {
    const allDecision = decisionOption[0].value;
    if (watchDecision === allDecision) {
      return decisionOption
        .filter(d => d?.value !== allDecision)
        .map(d => ({
          label: `Decision ${d.value}, for runtime ${watchRuntime} at ${watchDate}`,
          runTime: watchRuntime,
          decision: d?.value,
          date: moment(watchDate, 'YYYY-M-DD').format('DD-MM-YYYY'),
        }));
    }
    return [
      {
        label: `Decision ${watchDecision}, for runtime ${watchDecision} at ${watchDate}`,
        runTime: watchRuntime,
        decision: watchDecision,
        date: moment(watchDate, 'YYYY-M-DD').format('DD-MM-YYYY'),
      },
    ];
  };

  const renderGraphs = () =>
    getGraphFilterResult().map((graphUrl, index) => (
      <div key={`graphUrl-${index}`}>
        <Card className="jh-card map-cards">
          <h3>{isError ? 'No result found !' : graphUrl?.label || ''}</h3>
          <div>
            <div className={`png-graphs ${isError ? 'error-png-graphs' : ''}`}>
              <img
                onError={(e: any) => {
                  setError(true);
                  e.target.src = 'content/images/no-data-found.png';
                }}
                onLoad={(e: any) => {
                  if (e.target.currentSrc?.includes('no-data-found.png')) {
                    setError(true);
                  } else {
                    setError(false);
                  }
                }}
                // sample url: http://d26r5s4zfzzr4r.cloudfront.net/images/26-9-2020/00/d01/image_01.png
                src={`${window.location.origin}/maps/${graphUrl?.date}/${graphUrl?.runTime || '00'}/d${graphUrl?.decision || '01'}/image_${
                  graphUrl?.decision || '01'
                }.png`}
                alt={`graphs_${graphUrl?.decision || '01'}`}
              />
            </div>
          </div>
        </Card>
      </div>
    ));

  return (
    <div>
      {renderGraphFilter()}
      {renderGraphs()}
    </div>
  );
};
