import React from 'react';

function DeleteIcon({ onClick, testId }: { onClick?: (e) => void; testId?: string }) {
  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" data-cy={testId} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g id="Delete">
        <path
          id="Vector"
          d="M6.66669 7.33333V11.3333M9.33335 7.33333V11.3333M2.66669 4.66667H13.3334M4.00002 4.66667V12C4.00002 13.1046 4.89545 14 6.00002 14H10C11.1046 14 12 13.1046 12 12V4.66667H4.00002ZM10 4.66667V3.33333C10 2.59695 9.40307 2 8.66669 2H7.33335C6.59697 2 6.00002 2.59695 6.00002 3.33333V4.66667H10Z"
          stroke="#439A97"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default DeleteIcon;
