import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Row, Col, Card, Label, InputGroup, FormFeedback } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getCities, getCountyEntities } from 'app/entities/city/city.reducer';
import { getEntities as getStates } from 'app/entities/city/state.reducer';
import { reset, linkEntity } from '../device.reducer';
import { Controller, FieldError, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { getFilterByStateAndCounty, reactStrapStyles } from 'app/shared/util/form-utils';
import { getEntities as getNeighborhood } from '../../neighborhood/neighborhood.reducer';

export const DeviceLink = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const loading = useAppSelector(state => state.device.loading);
  const updating = useAppSelector(state => state.device.updating);
  const updateSuccess = useAppSelector(state => state.device.updateSuccess);
  const states = useAppSelector(state => state.states.menuOptions);
  const cities = useAppSelector(state => state.city.menuOptions);
  const counties = useAppSelector(state => state.city.countyMenuOptions);
  // const neighborhoods = useAppSelector(state => state.neighborhood.menuOptions);

  const handleClose = () => {
    navigate('/device' + location.search);
  };

  useEffect(() => {
    dispatch(reset());
    dispatch(getStates({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    dispatch(
      linkEntity({
        ...values,
        installationState: values.installationState.value,
        installationCounty: values.installationCounty.value,
        installationCity: values.installationCity.value,
        installationNeighborhood: 11,
      }),
    );
  };

  return (
    <Card className="jh-card">
      <Row className="justify-content-start">
        <Col md="12">
          <h2 id="link-device-heading" data-cy="link-device-heading">
            Setup a device
          </h2>
        </Col>
      </Row>
      <div className="mt-10">&nbsp;</div>
      <div>
        <Row className="justify-content-start">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DeviceLinkForm
              updating={updating}
              dispatch={dispatch}
              states={states}
              cities={cities}
              counties={counties}
              // neighborhoods={neighborhoods}
              saveEntity={saveEntity}
            ></DeviceLinkForm>
          )}
        </Row>
      </div>
    </Card>
  );
};

export const DeviceLinkForm = ({ saveEntity, dispatch, cities, counties, states, updating }) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const watchState = watch('installationState');
  return (
    <>
      <form onSubmit={vals => void handleSubmit(saveEntity)(vals)}>
        <div className="form-body">
          <div className="d-flex">
            <ValidatedField
              label={translate('rainspotApp.device.serialNumber') + '*'}
              id="serialNumber"
              name="serialNumber"
              data-cy="serialNumber"
              register={register}
              error={errors.serialNumber as FieldError}
              type="text"
              validate={{
                required: { value: true, message: translate('register.messages.validate.serialNumber.required') },
                minLength: { value: 1, message: translate('register.messages.validate.serialNumber.minlength') },
                maxLength: { value: 50, message: translate('register.messages.validate.serialNumber.maxlength') },
              }}
            />
          </div>
          <div className="d-flex">
            {' '}
            <ValidatedField
              label={translate('global.form.installationAddress.label') + '*'}
              id="installationAddress"
              name="installationAddress"
              register={register}
              error={errors.installationAddress as FieldError}
              data-cy="installationAddress"
              type="text"
              validate={{
                required: { value: true, message: translate('register.messages.validate.address.required') },
                minLength: { value: 1, message: translate('register.messages.validate.address.minlength') },
                maxLength: { value: 100, message: translate('register.messages.validate.address.maxlength') },
              }}
            />
            <div style={{ display: 'flex' }} className="flex-column">
              <Label for="state">{'State*'}</Label>
              <InputGroup className="mb-3" error={!!errors?.installationState} data-testid={'state'}>
                <Controller
                  control={control}
                  name="installationState"
                  rules={{ required: 'State is required' }}
                  render={({ field, fieldState }) => {
                    return (
                      <>
                        <ReactSelect
                          id={'installationState'}
                          menuPlacement="auto"
                          classNamePrefix="state"
                          options={states}
                          styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                          value={field.value}
                          onChange={(option: any) => {
                            field.onChange(option);
                            dispatch(getCountyEntities(option.value));
                            setValue('installationCounty', null);
                            setValue('installationCity', null);
                            setValue('installationNeighborhood', 11);
                          }}
                        />

                        <FormFeedback>{fieldState.error?.message}</FormFeedback>
                      </>
                    );
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div className="d-flex">
            <div style={{ display: 'flex' }} className="flex-column">
              <Label for="city">{'County*'}</Label>
              <InputGroup className="mb-3" error={!!errors?.installationCounty} data-testid={'county'}>
                <Controller
                  control={control}
                  name="installationCounty"
                  rules={{ required: 'County is required' }}
                  render={({ field, fieldState }) => {
                    return (
                      <>
                        <ReactSelect
                          menuPlacement="auto"
                          id={'installationCounty'}
                          classNamePrefix="county"
                          options={counties}
                          styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                          value={field.value}
                          onChange={(option: any) => {
                            field.onChange(option);
                            const filterUrl = getFilterByStateAndCounty(watchState?.value, option.value);
                            dispatch(getCities({ filterUrl }));
                            setValue('installationCity', null);
                            setValue('installationNeighborhood', 11);
                          }}
                        />

                        <FormFeedback>{fieldState.error?.message}</FormFeedback>
                      </>
                    );
                  }}
                />
              </InputGroup>
            </div>
            <div style={{ display: 'flex' }} className="flex-column">
              <Label for="city">{'City*'}</Label>
              <InputGroup className="mb-3" error={!!errors?.installationCity} data-testid={'city'}>
                <Controller
                  control={control}
                  name="installationCity"
                  rules={{ required: 'City is required' }}
                  render={({ field, fieldState }) => {
                    return (
                      <>
                        <ReactSelect
                          menuPlacement="auto"
                          id={'installationCity'}
                          classNamePrefix="city"
                          options={cities}
                          styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                          value={field.value}
                          onChange={(option: any) => {
                            field.onChange(option);
                            dispatch(getNeighborhood({ filterUrl: `${'cityId.equals=' + option.value}&` }));
                            setValue('installationNeighborhood', null);
                          }}
                        />

                        <FormFeedback>{fieldState.error?.message}</FormFeedback>
                      </>
                    );
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div className="d-flex">
            {/* <div style={{ display: 'flex' }} className="flex-column">
              <Label for="neighborhood">{'Neighborhood*'}</Label>
              <InputGroup className="mb-3" error={!!errors?.installationNeighborhood} data-testid={'neighborhood'}>
                <Controller
                  control={control}
                  name="installationNeighborhood"
                  rules={{ required: 'Neighborhood is required' }}
                  render={({ field, fieldState }) => {
                    return (
                      <>
                        <ReactSelect
                          menuPlacement="auto"
                          id={'installationNeighborhood'}
                          classNamePrefix="neighborhood"
                          options={neighborhoods}
                          styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
                          value={field.value}
                          onChange={(file: any) => {
                            field.onChange(file);
                          }}
                        />

                        <FormFeedback>{fieldState.error?.message}</FormFeedback>
                      </>
                    );
                  }}
                />
              </InputGroup>
            </div> */}
            {
              // <div style={{ display: 'flex' }} className="flex-column">
              //   <Label for="selectedMaster">{'Select Master'}</Label>
              //   <InputGroup className="mb-3" error={!!errors?.installationNeighborhood} data-testid={'selectedMaster'}>
              //     <Controller
              //       control={control}
              //       name="selectedMaster"
              //       rules={{ required: false }}
              //       render={({ field, fieldState }) => {
              //         return (
              //           <>
              //             <ReactSelect
              //               menuPlacement="auto"
              //               id={'selectedMaster'}
              //               classNamePrefix="selectedMaster"
              //               options={neighborhoods}
              //               styles={reactStrapStyles({ error: !!fieldState?.error?.message })}
              //               value={field.value}
              //               onChange={(file: any) => {
              //                 field.onChange(file);
              //               }}
              //             />
              //             <FormFeedback>{fieldState.error?.message}</FormFeedback>
              //           </>
              //         );
              //       }}
              //     />
              //   </InputGroup>
              // </div>
            }
            <ValidatedField
              label={'Zipcode*'}
              id="installationZipCode"
              name="installationZipCode"
              data-cy="installationZipCode"
              type="number"
              register={register}
              error={errors.installationZipCode as FieldError}
              validate={{
                required: { value: true, message: translate('register.messages.validate.zipcode.required') },
                minLength: { value: 1, message: translate('register.messages.validate.zipcode.minlength') },
                maxLength: { value: 16, message: translate('register.messages.validate.zipcode.maxlength') },
              }}
            />
          </div>
        </div>
        <hr className="solid-divider" />
        <div className={`justify-content-end d-flex form-footer`}>
          <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/device" replace color="info">
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.cancel">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
            <Translate contentKey="entity.action.save">Save</Translate>
          </Button>
        </div>
      </form>
    </>
  );
};
export default DeviceLink;
