import React from 'react';

function RoleIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M2.66669 3.33334L10 3.33334M2.66669 5.33334H10M2.66669 7.33334H7.33335M12.3042 9.02819L9.28453 12.0479C9.09839 12.234 8.86132 12.3609 8.6032 12.4126L7.21023 12.6911L7.48882 11.2982C7.54045 11.04 7.66732 10.803 7.85345 10.6168L10.8731 7.59712M12.3042 9.02819L13.0567 8.27569C13.317 8.01534 13.317 7.59323 13.0567 7.33288L12.5684 6.84462C12.3081 6.58427 11.886 6.58427 11.6256 6.84462L10.8731 7.59712M12.3042 9.02819L10.8731 7.59712"
        stroke="#FFFF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default RoleIcon;
